export default {
  "sidebar-guangjian": () => import("/home/devops/workspace/webspace-zl/apps/platform/layouts/sidebar-guangjian.vue").then(m => m.default || m),
  "sidebar-nosession": () => import("/home/devops/workspace/webspace-zl/apps/platform/layouts/sidebar-nosession.vue").then(m => m.default || m),
  menu: () => import("/home/devops/workspace/webspace-zl/modules/portal/layouts/menu.ts").then(m => m.default || m),
  "multi-cols": () => import("/home/devops/workspace/webspace-zl/modules/portal/layouts/multi-cols.vue").then(m => m.default || m),
  "sidebar-nosession-v1": () => import("/home/devops/workspace/webspace-zl/modules/portal/layouts/sidebar-nosession.v1.vue").then(m => m.default || m),
  "sidebar-portal-header": () => import("/home/devops/workspace/webspace-zl/modules/portal/layouts/sidebar-portal-header.vue").then(m => m.default || m),
  "sidebar-td": () => import("/home/devops/workspace/webspace-zl/modules/portal/layouts/sidebar-td.vue").then(m => m.default || m),
  sidebar: () => import("/home/devops/workspace/webspace-zl/modules/portal/layouts/sidebar.vue").then(m => m.default || m),
  spline: () => import("/home/devops/workspace/webspace-zl/modules/portal/layouts/spline.vue").then(m => m.default || m),
  "chart-layout-old": () => import("/home/devops/workspace/webspace-zl/modules/yp231519/layouts/chart.layout.old.vue").then(m => m.default || m),
  "chart-layout": () => import("/home/devops/workspace/webspace-zl/modules/yp231519/layouts/chart.layout.vue").then(m => m.default || m),
  "data-overview": () => import("/home/devops/workspace/webspace-zl/modules/yp231519/layouts/data-overview.vue").then(m => m.default || m),
  header: () => import("/home/devops/workspace/webspace-zl/modules/yp231519/layouts/header.vue").then(m => m.default || m),
  "sidebar-nosession-v2": () => import("/home/devops/workspace/webspace-zl/modules/render/layouts/sidebar-nosession.v2.vue").then(m => m.default || m),
  "without-footer": () => import("/home/devops/workspace/webspace-zl/modules/render/layouts/without-footer.vue").then(m => m.default || m),
  "bim-nd-header": () => import("/home/devops/workspace/webspace-zl/modules/lifecycle/layouts/bim-nd-header.vue").then(m => m.default || m),
  "bim-nd-lifecycle": () => import("/home/devops/workspace/webspace-zl/modules/lifecycle/layouts/bim-nd-lifecycle.vue").then(m => m.default || m),
  types: () => import("/home/devops/workspace/webspace-zl/modules/lifecycle/layouts/types.ts").then(m => m.default || m)
}