import { default as docIh87sht4osMeta } from "/home/devops/workspace/webspace-zl/modules/openapi/src/runtime/pages/doc.vue?macro=true";
import { default as indexObcEGuDI97Meta } from "/home/devops/workspace/webspace-zl/modules/render/pages/admin/index.vue?macro=true";
import { default as _91id_933jJkU0JS3fMeta } from "/home/devops/workspace/webspace-zl/modules/yp231519/pages/admin/todos/[id].vue?macro=true";
import { default as indexS3bjUHw2RSMeta } from "/home/devops/workspace/webspace-zl/modules/yp231519/pages/admin/todos/index.vue?macro=true";
import { default as authX0tpgiyclWMeta } from "/home/devops/workspace/webspace-zl/modules/organization/pages/apps/[id]/auth.vue?macro=true";
import { default as detailOm0UHTpX6IMeta } from "/home/devops/workspace/webspace-zl/modules/organization/pages/apps/[id]/detail.vue?macro=true";
import { default as _91id_93TrZxXvj3GpMeta } from "/home/devops/workspace/webspace-zl/modules/organization/pages/apps/[id].vue?macro=true";
import { default as indexcSPsM2AqlgMeta } from "/home/devops/workspace/webspace-zl/modules/organization/pages/apps/index.vue?macro=true";
import { default as listIKtdb2YBTkMeta } from "/home/devops/workspace/webspace-zl/modules/organization/pages/apps/list.vue?macro=true";
import { default as _91oid_93FWn3AxWQHNMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/analyze/collision/[oid].vue?macro=true";
import { default as ViewerJuNK8opFomMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/analyze/collision/components/Viewer.vue?macro=true";
import { default as ViewerZJL8EH8CJ0ZzMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/analyze/collision/components/ViewerZJ.vue?macro=true";
import { default as createTqf197mHZ7Meta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/analyze/collision/create.vue?macro=true";
import { default as indexdPM85xDI8lMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/analyze/collision/index.vue?macro=true";
import { default as _91id_936SE4vZ9Z8YMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/analyze/diff/[id].vue?macro=true";
import { default as BimDoubleViewerj6FAthMb3UMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/analyze/diff/components/BimDoubleViewer.vue?macro=true";
import { default as BimDoubleViewerZJ7jKjuWucojMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/analyze/diff/components/BimDoubleViewerZJ.vue?macro=true";
import { default as indexKdJ6mj8lKTMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/analyze/diff/index.vue?macro=true";
import { default as indexH6OIsjbWZCMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/analyze/graph/index.vue?macro=true";
import { default as indexH3MTnMvLNLMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/analyze/trace/index.vue?macro=true";
import { default as editnRJCIr7p74Meta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/catalog/component/edit.vue?macro=true";
import { default as index3bbCLOZSnpMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/catalog/component/index.vue?macro=true";
import { default as edit_46oldMLZkgGF9lfMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/catalog/model/edit.old.vue?macro=true";
import { default as edit8kE1TQqcgmMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/catalog/model/edit.vue?macro=true";
import { default as indexeSeS0pvpoVMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/catalog/model/index.vue?macro=true";
import { default as _91id_93fnWixLfX1OMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/checker/quality/[id].vue?macro=true";
import { default as CertainRuleCRm6PaDpW1JiMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/checker/quality/compoents/CertainRuleCR.vue?macro=true";
import { default as DefaultLayoutnWWZ78mV7WMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/checker/quality/compoents/DefaultLayout.vue?macro=true";
import { default as ModelCheckResultBrowserDrFMGAZEyQMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/checker/quality/compoents/ModelCheckResultBrowser.vue?macro=true";
import { default as Viewer9O2b7xhNCqMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/checker/quality/compoents/Viewer.vue?macro=true";
import { default as ViewerZJh7A6PWRS9mMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/checker/quality/compoents/ViewerZJ.vue?macro=true";
import { default as createHBhTqJ5s6HMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/checker/quality/create.vue?macro=true";
import { default as indexwz1erUt5sYMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/checker/quality/index.vue?macro=true";
import { default as listaAr5yeHvfsMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/checker/quality/list.vue?macro=true";
import { default as indexRSuD5BajbuMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/checker/topology/index.vue?macro=true";
import { default as listQxMVXvRA4dMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/checker/topology/list.vue?macro=true";
import { default as georeferencing256hIuew2tMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/collection/model/[id]/georeferencing.vue?macro=true";
import { default as index0qbOnsrkodMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/collection/model/[id]/index.vue?macro=true";
import { default as listIAbAtkd1YiMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/collection/model/list.vue?macro=true";
import { default as overviewrTXT1xsQ2SMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/collection/model/overview.vue?macro=true";
import { default as trashsEgxuXv2wpMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/collection/model/trash.vue?macro=true";
import { default as modelrq6BnruOUEMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/collection/model.vue?macro=true";
import { default as index3VFshyrLU5Meta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/converter/coordinate/index.vue?macro=true";
import { default as result0Y13LzzTE3Meta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/converter/format/gfc2ifc/[id]/result.vue?macro=true";
import { default as index1sN5eeY304Meta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/converter/format/gfc2ifc/create/index.vue?macro=true";
import { default as indexBVSNsM8jyhMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/converter/format/gfc2ifc/list/index.vue?macro=true";
import { default as indexwUI9sQFq5TMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/converter/format/index.vue?macro=true";
import { default as resultuM0zbhYVY0Meta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/converter/format/p3d2ifc/[id]/result.vue?macro=true";
import { default as indexhAzTDWr4xEMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/converter/format/p3d2ifc/create/index.vue?macro=true";
import { default as indexy8j9IF2Ci8Meta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/converter/format/p3d2ifc/list/index.vue?macro=true";
import { default as result8LZyUOsOotMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/converter/format/rvt2ifc/[id]/result.vue?macro=true";
import { default as index35gqZbUNUEMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/converter/format/rvt2ifc/create/index.vue?macro=true";
import { default as indexXtnvwBEHRsMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/converter/format/rvt2ifc/list/index.vue?macro=true";
import { default as indexm1mTMHnBZ3Meta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/explore/image/index.vue?macro=true";
import { default as _91id_9317UKRUDmZVMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/explore/model/[id].vue?macro=true";
import { default as indexC4hJESJpqoMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/explore/model/index.vue?macro=true";
import { default as _91id_93HOwwXCPQa3Meta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/graphic/component/[id].vue?macro=true";
import { default as listIhgW9vVl8vMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/graphic/component/list.vue?macro=true";
import { default as trash7C19HCi9PrMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/graphic/component/trash.vue?macro=true";
import { default as componentEfDA4lbdRnMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/graphic/component.vue?macro=true";
import { default as index5gcrJS5PugMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/graphic/index.vue?macro=true";
import { default as _91id_93q6fdaHiqGtMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/graphic/model/[id].vue?macro=true";
import { default as listfwgWFuMYUTMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/graphic/model/list.vue?macro=true";
import { default as trashOW0n4dOBA0Meta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/graphic/model/trash.vue?macro=true";
import { default as modeltlgp3EAqs0Meta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/graphic/model.vue?macro=true";
import { default as home2ftkLMC5ZYMeta } from "/home/devops/workspace/webspace-zl/modules/yp231519/pages/bim/[project_id]/overview/home.vue?macro=true";
import { default as indexqDA2o7YiNDMeta } from "/home/devops/workspace/webspace-zl/modules/yp231519/pages/bim/[project_id]/overview/index.vue?macro=true";
import { default as indexOld7XJWHUzMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/process/extract/create/index.vue?macro=true";
import { default as indexz2GOEj8PFQMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/process/extract/index.vue?macro=true";
import { default as indexRDvaKZr95nMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/process/merge/create/index.vue?macro=true";
import { default as index9GjSGzgZxcMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/process/merge/index.vue?macro=true";
import { default as _91id_93xN9dkHZTniMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/programme/device/[id].vue?macro=true";
import { default as SelectTree3oemXPk9ThMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/programme/device/components/SelectTree.vue?macro=true";
import { default as useCreateDesignPlanxnMppPU2GOMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/programme/device/composables/useCreateDesignPlan.ts?macro=true";
import { default as machinefHx0M4b6F9Meta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/programme/device/machine.vue?macro=true";
import { default as standardxMT5nhiEClMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/programme/device/standard.vue?macro=true";
import { default as verticalyTJxrVr8URMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/programme/device/vertical.vue?macro=true";
import { default as devicexrbbGmr6wxMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/programme/device.vue?macro=true";
import { default as pluginhuFA8kM6wbMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/transmission/upload/plugin.vue?macro=true";
import { default as uploadAuklDwJO5EMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/transmission/upload.vue?macro=true";
import { default as indexLek8dHSFZvMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/index.vue?macro=true";
import { default as _91id_93E9oyTJwDwlMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/todos/[id].vue?macro=true";
import { default as indexkwKOewMcPBMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/todos/index.vue?macro=true";
import { default as viewerk8MQyDcIdvMeta } from "/home/devops/workspace/webspace-zl/modules/bim/pages/bim/viewer.vue?macro=true";
import { default as _91content_93fqX7tot7EEMeta } from "/home/devops/workspace/webspace-zl/apps/platform/pages/blog/[content].vue?macro=true";
import { default as indexsoNOP787EMMeta } from "/home/devops/workspace/webspace-zl/apps/platform/pages/blog/index.vue?macro=true";
import { default as indexpNSycpOte6Meta } from "/home/devops/workspace/webspace-zl/modules/bom/pages/bom/[project_id]/material-list/admin/index.vue?macro=true";
import { default as index7X9EC2Fl2bMeta } from "/home/devops/workspace/webspace-zl/modules/bom/pages/bom/[project_id]/material-list/index.vue?macro=true";
import { default as indexYSVJXC15mRMeta } from "/home/devops/workspace/webspace-zl/modules/bom/pages/bom/index.vue?macro=true";
import { default as _91key_93KH24EDjgLbMeta } from "/home/devops/workspace/webspace-zl/modules/catalog/pages/catalog/admin/catalogs/[key].vue?macro=true";
import { default as create3nRAzEKohKMeta } from "/home/devops/workspace/webspace-zl/modules/catalog/pages/catalog/admin/catalogs/create.vue?macro=true";
import { default as index3NadgppKzmMeta } from "/home/devops/workspace/webspace-zl/modules/catalog/pages/catalog/admin/catalogs/index.vue?macro=true";
import { default as indexTChmTq8HbWMeta } from "/home/devops/workspace/webspace-zl/modules/catalog/pages/catalog/admin/index.vue?macro=true";
import { default as _91id_93lXP7u8GXSOMeta } from "/home/devops/workspace/webspace-zl/modules/catalog/pages/catalog/admin/todos/[id].vue?macro=true";
import { default as createGkT8Gv8NAaMeta } from "/home/devops/workspace/webspace-zl/modules/catalog/pages/catalog/admin/todos/create.vue?macro=true";
import { default as indexfKK4GvtgE5Meta } from "/home/devops/workspace/webspace-zl/modules/catalog/pages/catalog/admin/todos/index.vue?macro=true";
import { default as edituCBsV6G8dBMeta } from "/home/devops/workspace/webspace-zl/modules/catalog/pages/catalog/catalog/edit.vue?macro=true";
import { default as listFN6XL3v5U8Meta } from "/home/devops/workspace/webspace-zl/modules/catalog/pages/catalog/catalog/list.vue?macro=true";
import { default as actionYCmFycXwzwMeta } from "/home/devops/workspace/webspace-zl/modules/flow/pages/flow/[project_id]/approve/mine/[id]/action.vue?macro=true";
import { default as detail0jLnIXXCrfMeta } from "/home/devops/workspace/webspace-zl/modules/flow/pages/flow/[project_id]/approve/mine/[id]/detail.vue?macro=true";
import { default as indexhX1KGdhVGqMeta } from "/home/devops/workspace/webspace-zl/modules/flow/pages/flow/[project_id]/approve/mine/index.vue?macro=true";
import { default as doneKwZsNF31X1Meta } from "/home/devops/workspace/webspace-zl/modules/flow/pages/flow/[project_id]/approve/mine/record/done.vue?macro=true";
import { default as receiveH3shQZzLUkMeta } from "/home/devops/workspace/webspace-zl/modules/flow/pages/flow/[project_id]/approve/mine/record/receive.vue?macro=true";
import { default as startgfM9H7x4xUMeta } from "/home/devops/workspace/webspace-zl/modules/flow/pages/flow/[project_id]/approve/mine/record/start.vue?macro=true";
import { default as undoiDei0UizASMeta } from "/home/devops/workspace/webspace-zl/modules/flow/pages/flow/[project_id]/approve/mine/record/undo.vue?macro=true";
import { default as recordIaJ3QabtiFMeta } from "/home/devops/workspace/webspace-zl/modules/flow/pages/flow/[project_id]/approve/mine/record.vue?macro=true";
import { default as baseRppgt7L49fMeta } from "/home/devops/workspace/webspace-zl/modules/flow/pages/flow/[project_id]/manage/approvals/[id]/base.vue?macro=true";
import { default as formysAcIfbb0zMeta } from "/home/devops/workspace/webspace-zl/modules/flow/pages/flow/[project_id]/manage/approvals/[id]/form.vue?macro=true";
import { default as nodet3JCF4KEpUMeta } from "/home/devops/workspace/webspace-zl/modules/flow/pages/flow/[project_id]/manage/approvals/[id]/node.vue?macro=true";
import { default as _91id_93x1HlB15pDuMeta } from "/home/devops/workspace/webspace-zl/modules/flow/pages/flow/[project_id]/manage/approvals/[id].vue?macro=true";
import { default as indexKoWAEFo3yCMeta } from "/home/devops/workspace/webspace-zl/modules/flow/pages/flow/[project_id]/manage/approvals/index.vue?macro=true";
import { default as indexnUrnf2c3SCMeta } from "/home/devops/workspace/webspace-zl/modules/flow/pages/flow/[project_id]/manage/index.vue?macro=true";
import { default as actionEGj9VluvzVMeta } from "/home/devops/workspace/webspace-zl/modules/flow/pages/flow/[project_id]/manage/list/[id]/action.vue?macro=true";
import { default as index7qoS4Q6xyBMeta } from "/home/devops/workspace/webspace-zl/modules/flow/pages/flow/[project_id]/manage/list/index.vue?macro=true";
import { default as indexTVMl7TT0EcMeta } from "/home/devops/workspace/webspace-zl/apps/platform/pages/index.vue?macro=true";
import { default as indexV0nKPMDIv1Meta } from "/home/devops/workspace/webspace-zl/modules/lifecycle/pages/lifecycle/[project_id]/ifc/index.vue?macro=true";
import { default as modelsBDpQOiVLKIMeta } from "/home/devops/workspace/webspace-zl/modules/lifecycle/pages/lifecycle/[project_id]/models.vue?macro=true";
import { default as indexfsJgHjtm2rMeta } from "/home/devops/workspace/webspace-zl/modules/lifecycle/pages/lifecycle/index.vue?macro=true";
import { default as login_46authjskT5R91vjoVMeta } from "/home/devops/workspace/webspace-zl/framework/pages/login.authjs.vue?macro=true";
import { default as loginwhOoGUg0VlMeta } from "/home/devops/workspace/webspace-zl/framework/pages/login.vue?macro=true";
import { default as MainLayoutlSne8gS6efMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/MainLayout.vue?macro=true";
import { default as index72V1YteB04Meta } from "/home/devops/workspace/webspace-zl/modules/monitor/pages/monitor/[project_id]/map/index.vue?macro=true";
import { default as indexEcBGDQ4Zh1Meta } from "/home/devops/workspace/webspace-zl/modules/monitor/pages/monitor/[project_id]/operate/index.vue?macro=true";
import { default as indexUU6NkIRntHMeta } from "/home/devops/workspace/webspace-zl/modules/monitor/pages/monitor/[project_id]/overview/index.vue?macro=true";
import { default as indexuGADjSi4WjMeta } from "/home/devops/workspace/webspace-zl/modules/monitor/pages/monitor/[project_id]/players/index.vue?macro=true";
import { default as indexsyDI8tHxYkMeta } from "/home/devops/workspace/webspace-zl/modules/monitor/pages/monitor/[project_id]/receivers/index.vue?macro=true";
import { default as index1CAtiak6h1Meta } from "/home/devops/workspace/webspace-zl/modules/om/pages/om/index.vue?macro=true";
import { default as indexZypG1sNlHBMeta } from "/home/devops/workspace/webspace-zl/modules/om/pages/om/orgs/index.vue?macro=true";
import { default as indexR6XmKiCY5VMeta } from "/home/devops/workspace/webspace-zl/modules/organization/pages/organization/[oid]/access/auth/index.vue?macro=true";
import { default as indexhHwZ7Lrj16Meta } from "/home/devops/workspace/webspace-zl/modules/organization/pages/organization/[oid]/access/role/index.vue?macro=true";
import { default as indexbUIMLAtXF2Meta } from "/home/devops/workspace/webspace-zl/modules/organization/pages/organization/[oid]/culture/appearance/index.vue?macro=true";
import { default as index9yv3UANk2gMeta } from "/home/devops/workspace/webspace-zl/modules/organization/pages/organization/[oid]/culture/dict/index.vue?macro=true";
import { default as indexO823mEDVbcMeta } from "/home/devops/workspace/webspace-zl/modules/organization/pages/organization/[oid]/culture/field/index.vue?macro=true";
import { default as apiauthtvasEVOPrdMeta } from "/home/devops/workspace/webspace-zl/modules/organization/pages/organization/[oid]/employee/[employee_oid]/apiauth.vue?macro=true";
import { default as basicFP3G04jQCqMeta } from "/home/devops/workspace/webspace-zl/modules/organization/pages/organization/[oid]/employee/[employee_oid]/basic.vue?macro=true";
import { default as _91employee_oid_93bRQMQw3oJdMeta } from "/home/devops/workspace/webspace-zl/modules/organization/pages/organization/[oid]/employee/[employee_oid].vue?macro=true";
import { default as basicZGMqpEwxkAMeta } from "/home/devops/workspace/webspace-zl/modules/organization/pages/organization/[oid]/info/basic.vue?macro=true";
import { default as mapmXBTy4PEWFMeta } from "/home/devops/workspace/webspace-zl/modules/organization/pages/organization/[oid]/info/map.vue?macro=true";
import { default as infoOYMwVztOSeMeta } from "/home/devops/workspace/webspace-zl/modules/organization/pages/organization/[oid]/info.vue?macro=true";
import { default as index4wv2tomBi7Meta } from "/home/devops/workspace/webspace-zl/modules/organization/pages/organization/[oid]/structure/department/index.vue?macro=true";
import { default as indexyGnIc7SgNIMeta } from "/home/devops/workspace/webspace-zl/modules/organization/pages/organization/[oid]/structure/employee/index.vue?macro=true";
import { default as indexOa7rqZOXocMeta } from "/home/devops/workspace/webspace-zl/modules/organization/pages/organization/[oid]/structure/group/index.vue?macro=true";
import { default as indexfnEXWcWyUsMeta } from "/home/devops/workspace/webspace-zl/modules/organization/pages/organization/[oid]/structure/v1/department/index.vue?macro=true";
import { default as normalWSn9y8X8CLMeta } from "/home/devops/workspace/webspace-zl/modules/organization/pages/organization/[oid]/structure/v1/employee/normal.vue?macro=true";
import { default as employeeRv5ALz4L07Meta } from "/home/devops/workspace/webspace-zl/modules/organization/pages/organization/[oid]/structure/v1/employee.vue?macro=true";
import { default as _91oid_93q8AuJNBQobMeta } from "/home/devops/workspace/webspace-zl/modules/organization/pages/organization/[oid].vue?macro=true";
import { default as indexozPzMr66fFMeta } from "/home/devops/workspace/webspace-zl/modules/organization/pages/organization/index.vue?macro=true";
import { default as indexobsRGJgVqjMeta } from "/home/devops/workspace/webspace-zl/modules/pm/pages/pm/admin/catalog/index.vue?macro=true";
import { default as org1eYjU1dv3wMeta } from "/home/devops/workspace/webspace-zl/modules/pm/pages/pm/admin/collaboration/org.vue?macro=true";
import { default as userLaKFke2gGpMeta } from "/home/devops/workspace/webspace-zl/modules/pm/pages/pm/admin/collaboration/user.vue?macro=true";
import { default as indexrxgSXmkgFEMeta } from "/home/devops/workspace/webspace-zl/modules/pm/pages/pm/admin/index.vue?macro=true";
import { default as focusoDFsuWPV1uMeta } from "/home/devops/workspace/webspace-zl/modules/pm/pages/pm/admin/project/focus.vue?macro=true";
import { default as indexegFMVE4osyMeta } from "/home/devops/workspace/webspace-zl/modules/pm/pages/pm/admin/project/index.vue?macro=true";
import { default as index0y5xap34QBMeta } from "/home/devops/workspace/webspace-zl/modules/pm/pages/pm/admin/project/old/index.vue?macro=true";
import { default as star4wGGKMaNUOMeta } from "/home/devops/workspace/webspace-zl/modules/pm/pages/pm/admin/project/star.vue?macro=true";
import { default as groupnVzgnTE0wnMeta } from "/home/devops/workspace/webspace-zl/modules/pm/pages/pm/admin/setting/group.vue?macro=true";
import { default as userWVq9zJSBD5Meta } from "/home/devops/workspace/webspace-zl/modules/pm/pages/pm/admin/setting/user.vue?macro=true";
import { default as indexqCjhMmAP8pMeta } from "/home/devops/workspace/webspace-zl/modules/pm/pages/pm/admin/system/index.vue?macro=true";
import { default as indexd81J7Kn6kJMeta } from "/home/devops/workspace/webspace-zl/modules/pm/pages/pm/index.vue?macro=true";
import { default as clientT5tm6Jt0bdMeta } from "/home/devops/workspace/webspace-zl/modules/portal/pages/portal/doc/client.vue?macro=true";
import { default as servercWg0qc72XQMeta } from "/home/devops/workspace/webspace-zl/modules/portal/pages/portal/doc/server.vue?macro=true";
import { default as indexNtKg6puGK0Meta } from "/home/devops/workspace/webspace-zl/modules/portal/pages/portal/example/compose/[id]/index.vue?macro=true";
import { default as indexsruerWNPKVMeta } from "/home/devops/workspace/webspace-zl/modules/portal/pages/portal/example/compose/index.vue?macro=true";
import { default as index9S8njmoVQsMeta } from "/home/devops/workspace/webspace-zl/modules/portal/pages/portal/example/exampleBrowsing/[id]/index.vue?macro=true";
import { default as indexWM2uV2J5kaMeta } from "/home/devops/workspace/webspace-zl/modules/portal/pages/portal/example/exampleBrowsing/index.vue?macro=true";
import { default as groupQuzHJkMw08Meta } from "/home/devops/workspace/webspace-zl/modules/portal/pages/portal/example/group.vue?macro=true";
import { default as clientqdj44Xep87Meta } from "/home/devops/workspace/webspace-zl/modules/portal/pages/portal/home/docs/client.vue?macro=true";
import { default as serverK0fdQnBWq2Meta } from "/home/devops/workspace/webspace-zl/modules/portal/pages/portal/home/docs/server.vue?macro=true";
import { default as _91id_9373aQylDBVkMeta } from "/home/devops/workspace/webspace-zl/modules/portal/pages/portal/home/example/[id].vue?macro=true";
import { default as indexVYjwnYfCwhMeta } from "/home/devops/workspace/webspace-zl/modules/portal/pages/portal/home/example/index.vue?macro=true";
import { default as _91id_930YmsDyPqsEMeta } from "/home/devops/workspace/webspace-zl/modules/portal/pages/portal/home/feature/[id].vue?macro=true";
import { default as indexWFVsy3wZFPMeta } from "/home/devops/workspace/webspace-zl/modules/portal/pages/portal/home/feature/index.vue?macro=true";
import { default as indexYQ48AXrNUaMeta } from "/home/devops/workspace/webspace-zl/modules/portal/pages/portal/home/index.vue?macro=true";
import { default as browseZM4nQDlKyDMeta } from "/home/devops/workspace/webspace-zl/modules/portal/pages/portal/home/standard/browse.vue?macro=true";
import { default as searchjLCmj2ZJWkMeta } from "/home/devops/workspace/webspace-zl/modules/portal/pages/portal/home/standard/search.vue?macro=true";
import { default as indexSAfUM2xDwmMeta } from "/home/devops/workspace/webspace-zl/modules/portal/pages/portal/scene/arrange/[id]/index.vue?macro=true";
import { default as indexX44Nrz5waDMeta } from "/home/devops/workspace/webspace-zl/modules/portal/pages/portal/scene/arrange/index.vue?macro=true";
import { default as groupoDzqdCRnSjMeta } from "/home/devops/workspace/webspace-zl/modules/portal/pages/portal/scene/group.vue?macro=true";
import { default as indexB8pVCO5NpQMeta } from "/home/devops/workspace/webspace-zl/modules/portal/pages/portal/scene/sceneBrowsing/[id]/index.vue?macro=true";
import { default as index2VyO8P8xmDMeta } from "/home/devops/workspace/webspace-zl/modules/portal/pages/portal/scene/sceneBrowsing/index.vue?macro=true";
import { default as prismaPzuoagO7lnMeta } from "/home/devops/workspace/webspace-zl/framework/pages/prisma.vue?macro=true";
import { default as indexPnQ5eB8aUSMeta } from "/home/devops/workspace/webspace-zl/modules/project-org/pages/project-org/access/member/index.vue?macro=true";
import { default as indexiS6gd0AXmjMeta } from "/home/devops/workspace/webspace-zl/modules/project-org/pages/project-org/access/policy/index.vue?macro=true";
import { default as indexqh8l70U8RUMeta } from "/home/devops/workspace/webspace-zl/modules/project-org/pages/project-org/access/role/index.vue?macro=true";
import { default as indexNA72epHhJOMeta } from "/home/devops/workspace/webspace-zl/modules/project-org/pages/project-org/culture/appearance/index.vue?macro=true";
import { default as indexoWoaqKScxRMeta } from "/home/devops/workspace/webspace-zl/modules/project-org/pages/project-org/culture/menu/index.vue?macro=true";
import { default as indexyHenp6uXxvMeta } from "/home/devops/workspace/webspace-zl/modules/project-org/pages/project-org/index.vue?macro=true";
import { default as indexxSGPX2MBriMeta } from "/home/devops/workspace/webspace-zl/modules/project-org/pages/project-org/integration/connect/index.vue?macro=true";
import { default as indexsxj1OhVta2Meta } from "/home/devops/workspace/webspace-zl/modules/project-org/pages/project-org/integration/iot/index.vue?macro=true";
import { default as index6l8IWqj0AHMeta } from "/home/devops/workspace/webspace-zl/modules/project/pages/project/[project_oid]/cehua/pbs/[id_name]/index.vue?macro=true";
import { default as catalog_45selectlwOl55rBNMMeta } from "/home/devops/workspace/webspace-zl/modules/project/pages/project/[project_oid]/cehua/pbs/catalog-select.vue?macro=true";
import { default as catalog_45viewUhzPCQEUtZMeta } from "/home/devops/workspace/webspace-zl/modules/project/pages/project/[project_oid]/cehua/pbs/catalog-view.vue?macro=true";
import { default as index_46oldK6ivWaTsLhMeta } from "/home/devops/workspace/webspace-zl/modules/project/pages/project/[project_oid]/cehua/pbs/index.old.vue?macro=true";
import { default as indexK6VHOVmMPhMeta } from "/home/devops/workspace/webspace-zl/modules/project/pages/project/[project_oid]/cehua/pbs/index.vue?macro=true";
import { default as normalK601V21X59Meta } from "/home/devops/workspace/webspace-zl/modules/project/pages/project/[project_oid]/cehua/unit/normal.vue?macro=true";
import { default as trashXkRBKuMJKTMeta } from "/home/devops/workspace/webspace-zl/modules/project/pages/project/[project_oid]/cehua/unit/trash.vue?macro=true";
import { default as unittU6ktyc2kSMeta } from "/home/devops/workspace/webspace-zl/modules/project/pages/project/[project_oid]/cehua/unit.vue?macro=true";
import { default as basicy30h0oK6mUMeta } from "/home/devops/workspace/webspace-zl/modules/project/pages/project/[project_oid]/config/basic.vue?macro=true";
import { default as indexUEw5PgNJiGMeta } from "/home/devops/workspace/webspace-zl/modules/project/pages/project/[project_oid]/index.vue?macro=true";
import { default as basic_45oldXxWZVG0eRtMeta } from "/home/devops/workspace/webspace-zl/modules/project/pages/project/[project_oid]/info/basic-old.vue?macro=true";
import { default as advanceSettingKRJgp2z4pAMeta } from "/home/devops/workspace/webspace-zl/modules/project/pages/project/[project_oid]/info/basic/advanceSetting.vue?macro=true";
import { default as extendInfoORWnvXKM6mMeta } from "/home/devops/workspace/webspace-zl/modules/project/pages/project/[project_oid]/info/basic/extendInfo.vue?macro=true";
import { default as indexx3WtM5HDPMMeta } from "/home/devops/workspace/webspace-zl/modules/project/pages/project/[project_oid]/info/basic/index.vue?macro=true";
import { default as basicUwVLVRisIAMeta } from "/home/devops/workspace/webspace-zl/modules/project/pages/project/[project_oid]/info/basic.vue?macro=true";
import { default as coverDjg5uG33nqMeta } from "/home/devops/workspace/webspace-zl/modules/project/pages/project/[project_oid]/info/cover.vue?macro=true";
import { default as map1yAexUZTypMeta } from "/home/devops/workspace/webspace-zl/modules/project/pages/project/[project_oid]/info/map.vue?macro=true";
import { default as protected9U4K1KWjdOMeta } from "/home/devops/workspace/webspace-zl/framework/pages/protected.vue?macro=true";
import { default as _91id_93un8GjHNI1KMeta } from "/home/devops/workspace/webspace-zl/modules/render/pages/render/[first_catalog]/[id].vue?macro=true";
import { default as _91id_93zCYgbcNMYXMeta } from "/home/devops/workspace/webspace-zl/modules/render/pages/render/[first_catalog]/[second_catalog]/[id].vue?macro=true";
import { default as index8kLU5BrdY9Meta } from "/home/devops/workspace/webspace-zl/modules/render/pages/render/[first_catalog]/[second_catalog]/index.vue?macro=true";
import { default as indexRbZ44RZmX2Meta } from "/home/devops/workspace/webspace-zl/modules/render/pages/render/[first_catalog]/index.vue?macro=true";
import { default as indexM781rcq4iEMeta } from "/home/devops/workspace/webspace-zl/modules/render/pages/render/doc/index.vue?macro=true";
import { default as editPLWCnYkTYGMeta } from "/home/devops/workspace/webspace-zl/modules/render/pages/render/example_old/[first_catalog]/[id]/edit.vue?macro=true";
import { default as indexM68MnxYDKVMeta } from "/home/devops/workspace/webspace-zl/modules/render/pages/render/example_old/[first_catalog]/[id]/index.vue?macro=true";
import { default as indexvl7kZWYRntMeta } from "/home/devops/workspace/webspace-zl/modules/render/pages/render/example_old/[first_catalog]/index.vue?macro=true";
import { default as _91id_93U2JC7MnCBFMeta } from "/home/devops/workspace/webspace-zl/modules/render/pages/render/example/scene/[id].vue?macro=true";
import { default as indexAPIwYlk48WMeta } from "/home/devops/workspace/webspace-zl/modules/render/pages/render/example/scene/index.vue?macro=true";
import { default as _91id_93BacTZ4Q6G4Meta } from "/home/devops/workspace/webspace-zl/modules/render/pages/render/gallery/[id].vue?macro=true";
import { default as indexonXuJQa20AMeta } from "/home/devops/workspace/webspace-zl/modules/render/pages/render/gallery/index.vue?macro=true";
import { default as indexWNra5jtU9UMeta } from "/home/devops/workspace/webspace-zl/modules/render/pages/render/scaffold/index.vue?macro=true";
import { default as _91id_932eSx6UX8CEMeta } from "/home/devops/workspace/webspace-zl/modules/render/pages/render/scenedisplay/[id].vue?macro=true";
import { default as indexKTysMnEKnwMeta } from "/home/devops/workspace/webspace-zl/modules/render/pages/render/scenedisplay/index.vue?macro=true";
import { default as doc_46oldMlIo2JwYCAMeta } from "/home/devops/workspace/webspace-zl/modules/render/pages/render/sdk/doc.old.vue?macro=true";
import { default as docPKOJ8tnZKGMeta } from "/home/devops/workspace/webspace-zl/modules/render/pages/render/sdk/doc.vue?macro=true";
import { default as doc_46oldJEEWmRYWGGMeta } from "/home/devops/workspace/webspace-zl/modules/render/pages/render/server_sdk/doc.old.vue?macro=true";
import { default as docRxbBZnouJuMeta } from "/home/devops/workspace/webspace-zl/modules/render/pages/render/server_sdk/doc.vue?macro=true";
import { default as indexLHXBwqhfzjMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/analyse/critical/index.vue?macro=true";
import { default as indexyOHr35jXH1Meta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/analyse/deviation/[id]/begin-work/index.vue?macro=true";
import { default as indexkqBz96fk7VMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/analyse/deviation/[id]/bim-model/index.vue?macro=true";
import { default as indexHCLSUvFAMTMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/analyse/deviation/[id]/duration/index.vue?macro=true";
import { default as indexYsGO6FcdJGMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/analyse/deviation/[id]/end-work/index.vue?macro=true";
import { default as _91id_93OuHkS650sSMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/analyse/deviation/[id].vue?macro=true";
import { default as indexJeYjVR3lA1Meta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/analyse/deviation/index.vue?macro=true";
import { default as indexgiA1wDlMJXMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/analyse/simulate/[id]/actual/index.vue?macro=true";
import { default as indexjOkvNKPsTzMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/analyse/simulate/[id]/plan/index.vue?macro=true";
import { default as _91id_93FonjLOr6iYMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/analyse/simulate/[id].vue?macro=true";
import { default as indexoNvemXQAPCMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/analyse/simulate/index.vue?macro=true";
import { default as indexZhO2OeVHlBMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/optimize/cost/index.vue?macro=true";
import { default as indexqv0LADtdpLMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/optimize/cost/tabs/analysis/index.vue?macro=true";
import { default as indexStGopMq1I7Meta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/optimize/cost/tabs/deviation/index.vue?macro=true";
import { default as indexcqqvX3wL1tMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/optimize/cost/tabs/information/index.vue?macro=true";
import { default as tabsQVcADg1YW7Meta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/optimize/cost/tabs.vue?macro=true";
import { default as indexHPKwfpNDYRMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/optimize/renew/[id]/now/index.vue?macro=true";
import { default as indexFGC7R0FHzJMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/optimize/renew/[id]/plan/index.vue?macro=true";
import { default as useSvgO8Cky92tHUMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/optimize/renew/[id]/useSvg.ts?macro=true";
import { default as _91id_93HD1aRz2o4tMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/optimize/renew/[id].vue?macro=true";
import { default as indexxe8TAdv9Y8Meta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/optimize/renew/index.vue?macro=true";
import { default as indexmx6LuqmznlMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/overview/index.vue?macro=true";
import { default as indexUm0Da5oRHzMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/plan/index.vue?macro=true";
import { default as indexA9vXXYtf6lMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/plan/main/index.vue?macro=true";
import { default as indexJnYjWQ4vRDMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/plan/major/index.vue?macro=true";
import { default as index56WSNxMjMxMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/plan/major/tabs/gantt-chart/index.vue?macro=true";
import { default as indexKnWwmpatwZMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/plan/major/tabs/network-diagram/index.vue?macro=true";
import { default as useSvgWzCqyHWwA2Meta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/plan/major/tabs/network-diagram/useSvg.ts?macro=true";
import { default as indexCpMuMv1vYaMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/plan/major/tabs/worksheet/index.vue?macro=true";
import { default as tabsxKyln0GPAjMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/plan/major/tabs.vue?macro=true";
import { default as index8niGiif2dCMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/plan/sub/[id]/bim-model/index.vue?macro=true";
import { default as indexHfKAmViDQnMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/plan/sub/[id]/gantt-chart/index.vue?macro=true";
import { default as indexOCb0QdL8bnMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/plan/sub/[id]/network-diagram/index.vue?macro=true";
import { default as useSvgBqzhVnoATjMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/plan/sub/[id]/network-diagram/useSvg.ts?macro=true";
import { default as key_45worksm241dV4cMcMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/plan/sub/[id]/overview/components/key-works.vue?macro=true";
import { default as indexZGmcvqpnoOMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/plan/sub/[id]/overview/index.vue?macro=true";
import { default as indexGTZ4JlPZlbMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/plan/sub/[id]/worksheet/index.vue?macro=true";
import { default as _91id_93l3WHTcn4opMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/plan/sub/[id].vue?macro=true";
import { default as indexsSAshZeOqPMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/plan/sub/index.vue?macro=true";
import { default as indexoRaENkzsLVMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/tracking/duration/[id]/index.vue?macro=true";
import { default as indexkBjf2vZ4msMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/tracking/duration/index.vue?macro=true";
import { default as indexmzi6vGKI17Meta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/tracking/entity/[id]/index.vue?macro=true";
import { default as indexpKbsaiVj6xMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/tracking/entity/index.vue?macro=true";
import { default as indexgSn8Mh1TnGMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/tracking/figure/index.vue?macro=true";
import { default as indexL7ieeUyD70Meta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/index.vue?macro=true";
import { default as _91id_93dpc48tutEvMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/todos/[id].vue?macro=true";
import { default as indexKSJtXfYDdmMeta } from "/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/todos/index.vue?macro=true";
import { default as entitycheckzN5CucxtHYMeta } from "/home/devops/workspace/webspace-zl/modules/standard/pages/standard/delivery/[id]/entitycheck.vue?macro=true";
import { default as geometrycheckdAvwsn420bMeta } from "/home/devops/workspace/webspace-zl/modules/standard/pages/standard/delivery/[id]/geometrycheck.vue?macro=true";
import { default as materialchecktBLcO4J35pMeta } from "/home/devops/workspace/webspace-zl/modules/standard/pages/standard/delivery/[id]/materialcheck.vue?macro=true";
import { default as propertycheckefHo2dnNfrMeta } from "/home/devops/workspace/webspace-zl/modules/standard/pages/standard/delivery/[id]/propertycheck.vue?macro=true";
import { default as quantitycheckTm48cV7Th0Meta } from "/home/devops/workspace/webspace-zl/modules/standard/pages/standard/delivery/[id]/quantitycheck.vue?macro=true";
import { default as _91id_93VbrnRYDcQDMeta } from "/home/devops/workspace/webspace-zl/modules/standard/pages/standard/delivery/[id].vue?macro=true";
import { default as indexuQ89XuwAkxMeta } from "/home/devops/workspace/webspace-zl/modules/standard/pages/standard/delivery/index.vue?macro=true";
import { default as _91id_938abran2sYZMeta } from "/home/devops/workspace/webspace-zl/modules/standard/pages/standard/dictionary/[id].vue?macro=true";
import { default as indexzXbBUXlEx4Meta } from "/home/devops/workspace/webspace-zl/modules/standard/pages/standard/dictionary/index.vue?macro=true";
import { default as entity2dwdSiMrQ2Meta } from "/home/devops/workspace/webspace-zl/modules/standard/pages/standard/schema/[id]/entity.vue?macro=true";
import { default as enumNQZF0TsdnbMeta } from "/home/devops/workspace/webspace-zl/modules/standard/pages/standard/schema/[id]/enum.vue?macro=true";
import { default as psetewNo1CGxopMeta } from "/home/devops/workspace/webspace-zl/modules/standard/pages/standard/schema/[id]/pset.vue?macro=true";
import { default as qsetRFTEPktbTqMeta } from "/home/devops/workspace/webspace-zl/modules/standard/pages/standard/schema/[id]/qset.vue?macro=true";
import { default as _91id_93z6m0OTZANaMeta } from "/home/devops/workspace/webspace-zl/modules/standard/pages/standard/schema/[id].vue?macro=true";
import { default as indexts6XMVWmIaMeta } from "/home/devops/workspace/webspace-zl/modules/standard/pages/standard/schema/index.vue?macro=true";
import { default as trpcnrefUGpdZVMeta } from "/home/devops/workspace/webspace-zl/framework/pages/trpc.vue?macro=true";
import { default as indexYLP9SgGAjGMeta } from "/home/devops/workspace/webspace-zl/modules/user-space/pages/user/info/index.vue?macro=true";
import { default as indexfPODb6hw02Meta } from "/home/devops/workspace/webspace-zl/modules/user-space/pages/user/security/index.vue?macro=true";
import { default as indexh3AGrTVJlKMeta } from "/home/devops/workspace/webspace-zl/modules/yp231519/pages/yp231519/index.vue?macro=true";
export default [
  {
    name: docIh87sht4osMeta?.name ?? "docs",
    path: docIh87sht4osMeta?.path ?? "/docs",
    meta: { ...(docIh87sht4osMeta || {}), ...{"apiDocUrl":"http://localhost:3001/api/docs","auth":false} },
    alias: docIh87sht4osMeta?.alias || [],
    redirect: docIh87sht4osMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/openapi/src/runtime/pages/doc.vue").then(m => m.default || m)
  },
  {
    name: docIh87sht4osMeta?.name ?? "docs-release",
    path: docIh87sht4osMeta?.path ?? "/docs/release",
    meta: { ...(docIh87sht4osMeta || {}), ...{"apiDocUrl":"https://cdn.hb6oss.xstore.ctyun.cn/doc/openapi.json","auth":false} },
    alias: docIh87sht4osMeta?.alias || [],
    redirect: docIh87sht4osMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/openapi/src/runtime/pages/doc.vue").then(m => m.default || m)
  },
  {
    name: docIh87sht4osMeta?.name ?? "docs",
    path: docIh87sht4osMeta?.path ?? "/docs",
    meta: { ...(docIh87sht4osMeta || {}), ...{"apiDocUrl":"/openapi/docs","auth":false} },
    alias: docIh87sht4osMeta?.alias || [],
    redirect: docIh87sht4osMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/openapi/src/runtime/pages/doc.vue").then(m => m.default || m)
  },
  {
    name: indexObcEGuDI97Meta?.name ?? "admin",
    path: indexObcEGuDI97Meta?.path ?? "/admin",
    meta: indexObcEGuDI97Meta || {},
    alias: indexObcEGuDI97Meta?.alias || [],
    redirect: indexObcEGuDI97Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/render/pages/admin/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_933jJkU0JS3fMeta?.name ?? "admin-todos-id",
    path: _91id_933jJkU0JS3fMeta?.path ?? "/admin/todos/:id()",
    meta: _91id_933jJkU0JS3fMeta || {},
    alias: _91id_933jJkU0JS3fMeta?.alias || [],
    redirect: _91id_933jJkU0JS3fMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/yp231519/pages/admin/todos/[id].vue").then(m => m.default || m)
  },
  {
    name: indexS3bjUHw2RSMeta?.name ?? "admin-todos",
    path: indexS3bjUHw2RSMeta?.path ?? "/admin/todos",
    meta: indexS3bjUHw2RSMeta || {},
    alias: indexS3bjUHw2RSMeta?.alias || [],
    redirect: indexS3bjUHw2RSMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/yp231519/pages/admin/todos/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93TrZxXvj3GpMeta?.name ?? "apps-id",
    path: _91id_93TrZxXvj3GpMeta?.path ?? "/apps/:id()",
    meta: _91id_93TrZxXvj3GpMeta || {},
    alias: _91id_93TrZxXvj3GpMeta?.alias || [],
    redirect: _91id_93TrZxXvj3GpMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/organization/pages/apps/[id].vue").then(m => m.default || m),
    children: [
  {
    name: authX0tpgiyclWMeta?.name ?? "apps-id-auth",
    path: authX0tpgiyclWMeta?.path ?? "auth",
    meta: authX0tpgiyclWMeta || {},
    alias: authX0tpgiyclWMeta?.alias || [],
    redirect: authX0tpgiyclWMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/organization/pages/apps/[id]/auth.vue").then(m => m.default || m)
  },
  {
    name: detailOm0UHTpX6IMeta?.name ?? "apps-id-detail",
    path: detailOm0UHTpX6IMeta?.path ?? "detail",
    meta: detailOm0UHTpX6IMeta || {},
    alias: detailOm0UHTpX6IMeta?.alias || [],
    redirect: detailOm0UHTpX6IMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/organization/pages/apps/[id]/detail.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexcSPsM2AqlgMeta?.name ?? "apps",
    path: indexcSPsM2AqlgMeta?.path ?? "/apps",
    meta: indexcSPsM2AqlgMeta || {},
    alias: indexcSPsM2AqlgMeta?.alias || [],
    redirect: indexcSPsM2AqlgMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/organization/pages/apps/index.vue").then(m => m.default || m)
  },
  {
    name: listIKtdb2YBTkMeta?.name ?? "apps-list",
    path: listIKtdb2YBTkMeta?.path ?? "/apps/list",
    meta: listIKtdb2YBTkMeta || {},
    alias: listIKtdb2YBTkMeta?.alias || [],
    redirect: listIKtdb2YBTkMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/organization/pages/apps/list.vue").then(m => m.default || m)
  },
  {
    name: _91oid_93FWn3AxWQHNMeta?.name ?? "bim-project_id-analyze-collision-oid",
    path: _91oid_93FWn3AxWQHNMeta?.path ?? "/bim/:project_id()/analyze/collision/:oid()",
    meta: _91oid_93FWn3AxWQHNMeta || {},
    alias: _91oid_93FWn3AxWQHNMeta?.alias || [],
    redirect: _91oid_93FWn3AxWQHNMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/analyze/collision/[oid].vue").then(m => m.default || m)
  },
  {
    name: ViewerJuNK8opFomMeta?.name ?? "bim-project_id-analyze-collision-components-Viewer",
    path: ViewerJuNK8opFomMeta?.path ?? "/bim/:project_id()/analyze/collision/components/Viewer",
    meta: ViewerJuNK8opFomMeta || {},
    alias: ViewerJuNK8opFomMeta?.alias || [],
    redirect: ViewerJuNK8opFomMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/analyze/collision/components/Viewer.vue").then(m => m.default || m)
  },
  {
    name: ViewerZJL8EH8CJ0ZzMeta?.name ?? "bim-project_id-analyze-collision-components-ViewerZJ",
    path: ViewerZJL8EH8CJ0ZzMeta?.path ?? "/bim/:project_id()/analyze/collision/components/ViewerZJ",
    meta: ViewerZJL8EH8CJ0ZzMeta || {},
    alias: ViewerZJL8EH8CJ0ZzMeta?.alias || [],
    redirect: ViewerZJL8EH8CJ0ZzMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/analyze/collision/components/ViewerZJ.vue").then(m => m.default || m)
  },
  {
    name: createTqf197mHZ7Meta?.name ?? "bim-project_id-analyze-collision-create",
    path: createTqf197mHZ7Meta?.path ?? "/bim/:project_id()/analyze/collision/create",
    meta: createTqf197mHZ7Meta || {},
    alias: createTqf197mHZ7Meta?.alias || [],
    redirect: createTqf197mHZ7Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/analyze/collision/create.vue").then(m => m.default || m)
  },
  {
    name: indexdPM85xDI8lMeta?.name ?? "bim-project_id-analyze-collision",
    path: indexdPM85xDI8lMeta?.path ?? "/bim/:project_id()/analyze/collision",
    meta: indexdPM85xDI8lMeta || {},
    alias: indexdPM85xDI8lMeta?.alias || [],
    redirect: indexdPM85xDI8lMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/analyze/collision/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_936SE4vZ9Z8YMeta?.name ?? "bim-project_id-analyze-diff-id",
    path: _91id_936SE4vZ9Z8YMeta?.path ?? "/bim/:project_id()/analyze/diff/:id()",
    meta: _91id_936SE4vZ9Z8YMeta || {},
    alias: _91id_936SE4vZ9Z8YMeta?.alias || [],
    redirect: _91id_936SE4vZ9Z8YMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/analyze/diff/[id].vue").then(m => m.default || m)
  },
  {
    name: BimDoubleViewerj6FAthMb3UMeta?.name ?? "bim-project_id-analyze-diff-components-BimDoubleViewer",
    path: BimDoubleViewerj6FAthMb3UMeta?.path ?? "/bim/:project_id()/analyze/diff/components/BimDoubleViewer",
    meta: BimDoubleViewerj6FAthMb3UMeta || {},
    alias: BimDoubleViewerj6FAthMb3UMeta?.alias || [],
    redirect: BimDoubleViewerj6FAthMb3UMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/analyze/diff/components/BimDoubleViewer.vue").then(m => m.default || m)
  },
  {
    name: BimDoubleViewerZJ7jKjuWucojMeta?.name ?? "bim-project_id-analyze-diff-components-BimDoubleViewerZJ",
    path: BimDoubleViewerZJ7jKjuWucojMeta?.path ?? "/bim/:project_id()/analyze/diff/components/BimDoubleViewerZJ",
    meta: BimDoubleViewerZJ7jKjuWucojMeta || {},
    alias: BimDoubleViewerZJ7jKjuWucojMeta?.alias || [],
    redirect: BimDoubleViewerZJ7jKjuWucojMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/analyze/diff/components/BimDoubleViewerZJ.vue").then(m => m.default || m)
  },
  {
    name: indexKdJ6mj8lKTMeta?.name ?? "bim-project_id-analyze-diff",
    path: indexKdJ6mj8lKTMeta?.path ?? "/bim/:project_id()/analyze/diff",
    meta: indexKdJ6mj8lKTMeta || {},
    alias: indexKdJ6mj8lKTMeta?.alias || [],
    redirect: indexKdJ6mj8lKTMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/analyze/diff/index.vue").then(m => m.default || m)
  },
  {
    name: indexH6OIsjbWZCMeta?.name ?? "bim-project_id-analyze-graph",
    path: indexH6OIsjbWZCMeta?.path ?? "/bim/:project_id()/analyze/graph",
    meta: indexH6OIsjbWZCMeta || {},
    alias: indexH6OIsjbWZCMeta?.alias || [],
    redirect: indexH6OIsjbWZCMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/analyze/graph/index.vue").then(m => m.default || m)
  },
  {
    name: indexH3MTnMvLNLMeta?.name ?? "bim-project_id-analyze-trace",
    path: indexH3MTnMvLNLMeta?.path ?? "/bim/:project_id()/analyze/trace",
    meta: indexH3MTnMvLNLMeta || {},
    alias: indexH3MTnMvLNLMeta?.alias || [],
    redirect: indexH3MTnMvLNLMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/analyze/trace/index.vue").then(m => m.default || m)
  },
  {
    name: editnRJCIr7p74Meta?.name ?? "bim-project_id-catalog-component-edit",
    path: editnRJCIr7p74Meta?.path ?? "/bim/:project_id()/catalog/component/edit",
    meta: editnRJCIr7p74Meta || {},
    alias: editnRJCIr7p74Meta?.alias || [],
    redirect: editnRJCIr7p74Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/catalog/component/edit.vue").then(m => m.default || m)
  },
  {
    name: index3bbCLOZSnpMeta?.name ?? "bim-project_id-catalog-component",
    path: index3bbCLOZSnpMeta?.path ?? "/bim/:project_id()/catalog/component",
    meta: index3bbCLOZSnpMeta || {},
    alias: index3bbCLOZSnpMeta?.alias || [],
    redirect: index3bbCLOZSnpMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/catalog/component/index.vue").then(m => m.default || m)
  },
  {
    name: edit_46oldMLZkgGF9lfMeta?.name ?? "bim-project_id-catalog-model-edit.old",
    path: edit_46oldMLZkgGF9lfMeta?.path ?? "/bim/:project_id()/catalog/model/edit.old",
    meta: edit_46oldMLZkgGF9lfMeta || {},
    alias: edit_46oldMLZkgGF9lfMeta?.alias || [],
    redirect: edit_46oldMLZkgGF9lfMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/catalog/model/edit.old.vue").then(m => m.default || m)
  },
  {
    name: edit8kE1TQqcgmMeta?.name ?? "bim-project_id-catalog-model-edit",
    path: edit8kE1TQqcgmMeta?.path ?? "/bim/:project_id()/catalog/model/edit",
    meta: edit8kE1TQqcgmMeta || {},
    alias: edit8kE1TQqcgmMeta?.alias || [],
    redirect: edit8kE1TQqcgmMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/catalog/model/edit.vue").then(m => m.default || m)
  },
  {
    name: indexeSeS0pvpoVMeta?.name ?? "bim-project_id-catalog-model",
    path: indexeSeS0pvpoVMeta?.path ?? "/bim/:project_id()/catalog/model",
    meta: indexeSeS0pvpoVMeta || {},
    alias: indexeSeS0pvpoVMeta?.alias || [],
    redirect: indexeSeS0pvpoVMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/catalog/model/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93fnWixLfX1OMeta?.name ?? "bim-project_id-checker-quality-id",
    path: _91id_93fnWixLfX1OMeta?.path ?? "/bim/:project_id()/checker/quality/:id()",
    meta: _91id_93fnWixLfX1OMeta || {},
    alias: _91id_93fnWixLfX1OMeta?.alias || [],
    redirect: _91id_93fnWixLfX1OMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/checker/quality/[id].vue").then(m => m.default || m)
  },
  {
    name: CertainRuleCRm6PaDpW1JiMeta?.name ?? "bim-project_id-checker-quality-compoents-CertainRuleCR",
    path: CertainRuleCRm6PaDpW1JiMeta?.path ?? "/bim/:project_id()/checker/quality/compoents/CertainRuleCR",
    meta: CertainRuleCRm6PaDpW1JiMeta || {},
    alias: CertainRuleCRm6PaDpW1JiMeta?.alias || [],
    redirect: CertainRuleCRm6PaDpW1JiMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/checker/quality/compoents/CertainRuleCR.vue").then(m => m.default || m)
  },
  {
    name: DefaultLayoutnWWZ78mV7WMeta?.name ?? "bim-project_id-checker-quality-compoents-DefaultLayout",
    path: DefaultLayoutnWWZ78mV7WMeta?.path ?? "/bim/:project_id()/checker/quality/compoents/DefaultLayout",
    meta: DefaultLayoutnWWZ78mV7WMeta || {},
    alias: DefaultLayoutnWWZ78mV7WMeta?.alias || [],
    redirect: DefaultLayoutnWWZ78mV7WMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/checker/quality/compoents/DefaultLayout.vue").then(m => m.default || m)
  },
  {
    name: ModelCheckResultBrowserDrFMGAZEyQMeta?.name ?? "bim-project_id-checker-quality-compoents-ModelCheckResultBrowser",
    path: ModelCheckResultBrowserDrFMGAZEyQMeta?.path ?? "/bim/:project_id()/checker/quality/compoents/ModelCheckResultBrowser",
    meta: ModelCheckResultBrowserDrFMGAZEyQMeta || {},
    alias: ModelCheckResultBrowserDrFMGAZEyQMeta?.alias || [],
    redirect: ModelCheckResultBrowserDrFMGAZEyQMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/checker/quality/compoents/ModelCheckResultBrowser.vue").then(m => m.default || m)
  },
  {
    name: Viewer9O2b7xhNCqMeta?.name ?? "bim-project_id-checker-quality-compoents-Viewer",
    path: Viewer9O2b7xhNCqMeta?.path ?? "/bim/:project_id()/checker/quality/compoents/Viewer",
    meta: Viewer9O2b7xhNCqMeta || {},
    alias: Viewer9O2b7xhNCqMeta?.alias || [],
    redirect: Viewer9O2b7xhNCqMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/checker/quality/compoents/Viewer.vue").then(m => m.default || m)
  },
  {
    name: ViewerZJh7A6PWRS9mMeta?.name ?? "bim-project_id-checker-quality-compoents-ViewerZJ",
    path: ViewerZJh7A6PWRS9mMeta?.path ?? "/bim/:project_id()/checker/quality/compoents/ViewerZJ",
    meta: ViewerZJh7A6PWRS9mMeta || {},
    alias: ViewerZJh7A6PWRS9mMeta?.alias || [],
    redirect: ViewerZJh7A6PWRS9mMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/checker/quality/compoents/ViewerZJ.vue").then(m => m.default || m)
  },
  {
    name: createHBhTqJ5s6HMeta?.name ?? "bim-project_id-checker-quality-create",
    path: createHBhTqJ5s6HMeta?.path ?? "/bim/:project_id()/checker/quality/create",
    meta: createHBhTqJ5s6HMeta || {},
    alias: createHBhTqJ5s6HMeta?.alias || [],
    redirect: createHBhTqJ5s6HMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/checker/quality/create.vue").then(m => m.default || m)
  },
  {
    name: indexwz1erUt5sYMeta?.name ?? "bim-project_id-checker-quality",
    path: indexwz1erUt5sYMeta?.path ?? "/bim/:project_id()/checker/quality",
    meta: indexwz1erUt5sYMeta || {},
    alias: indexwz1erUt5sYMeta?.alias || [],
    redirect: indexwz1erUt5sYMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/checker/quality/index.vue").then(m => m.default || m)
  },
  {
    name: listaAr5yeHvfsMeta?.name ?? "bim-project_id-checker-quality-list",
    path: listaAr5yeHvfsMeta?.path ?? "/bim/:project_id()/checker/quality/list",
    meta: listaAr5yeHvfsMeta || {},
    alias: listaAr5yeHvfsMeta?.alias || [],
    redirect: listaAr5yeHvfsMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/checker/quality/list.vue").then(m => m.default || m)
  },
  {
    name: indexRSuD5BajbuMeta?.name ?? "bim-project_id-checker-topology",
    path: indexRSuD5BajbuMeta?.path ?? "/bim/:project_id()/checker/topology",
    meta: indexRSuD5BajbuMeta || {},
    alias: indexRSuD5BajbuMeta?.alias || [],
    redirect: indexRSuD5BajbuMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/checker/topology/index.vue").then(m => m.default || m)
  },
  {
    name: listQxMVXvRA4dMeta?.name ?? "bim-project_id-checker-topology-list",
    path: listQxMVXvRA4dMeta?.path ?? "/bim/:project_id()/checker/topology/list",
    meta: listQxMVXvRA4dMeta || {},
    alias: listQxMVXvRA4dMeta?.alias || [],
    redirect: listQxMVXvRA4dMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/checker/topology/list.vue").then(m => m.default || m)
  },
  {
    name: modelrq6BnruOUEMeta?.name ?? "bim-project_id-collection-model",
    path: modelrq6BnruOUEMeta?.path ?? "/bim/:project_id()/collection/model",
    meta: modelrq6BnruOUEMeta || {},
    alias: modelrq6BnruOUEMeta?.alias || [],
    redirect: modelrq6BnruOUEMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/collection/model.vue").then(m => m.default || m),
    children: [
  {
    name: georeferencing256hIuew2tMeta?.name ?? "bim-project_id-collection-model-id-georeferencing",
    path: georeferencing256hIuew2tMeta?.path ?? ":id()/georeferencing",
    meta: georeferencing256hIuew2tMeta || {},
    alias: georeferencing256hIuew2tMeta?.alias || [],
    redirect: georeferencing256hIuew2tMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/collection/model/[id]/georeferencing.vue").then(m => m.default || m)
  },
  {
    name: index0qbOnsrkodMeta?.name ?? "bim-project_id-collection-model-id",
    path: index0qbOnsrkodMeta?.path ?? ":id()",
    meta: index0qbOnsrkodMeta || {},
    alias: index0qbOnsrkodMeta?.alias || [],
    redirect: index0qbOnsrkodMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/collection/model/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: listIAbAtkd1YiMeta?.name ?? "bim-project_id-collection-model-list",
    path: listIAbAtkd1YiMeta?.path ?? "list",
    meta: listIAbAtkd1YiMeta || {},
    alias: listIAbAtkd1YiMeta?.alias || [],
    redirect: listIAbAtkd1YiMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/collection/model/list.vue").then(m => m.default || m)
  },
  {
    name: overviewrTXT1xsQ2SMeta?.name ?? "bim-project_id-collection-model-overview",
    path: overviewrTXT1xsQ2SMeta?.path ?? "overview",
    meta: overviewrTXT1xsQ2SMeta || {},
    alias: overviewrTXT1xsQ2SMeta?.alias || [],
    redirect: overviewrTXT1xsQ2SMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/collection/model/overview.vue").then(m => m.default || m)
  },
  {
    name: trashsEgxuXv2wpMeta?.name ?? "bim-project_id-collection-model-trash",
    path: trashsEgxuXv2wpMeta?.path ?? "trash",
    meta: trashsEgxuXv2wpMeta || {},
    alias: trashsEgxuXv2wpMeta?.alias || [],
    redirect: trashsEgxuXv2wpMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/collection/model/trash.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index3VFshyrLU5Meta?.name ?? "bim-project_id-converter-coordinate",
    path: index3VFshyrLU5Meta?.path ?? "/bim/:project_id()/converter/coordinate",
    meta: index3VFshyrLU5Meta || {},
    alias: index3VFshyrLU5Meta?.alias || [],
    redirect: index3VFshyrLU5Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/converter/coordinate/index.vue").then(m => m.default || m)
  },
  {
    name: result0Y13LzzTE3Meta?.name ?? "bim-project_id-converter-format-gfc2ifc-id-result",
    path: result0Y13LzzTE3Meta?.path ?? "/bim/:project_id()/converter/format/gfc2ifc/:id()/result",
    meta: result0Y13LzzTE3Meta || {},
    alias: result0Y13LzzTE3Meta?.alias || [],
    redirect: result0Y13LzzTE3Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/converter/format/gfc2ifc/[id]/result.vue").then(m => m.default || m)
  },
  {
    name: index1sN5eeY304Meta?.name ?? "bim-project_id-converter-format-gfc2ifc-create",
    path: index1sN5eeY304Meta?.path ?? "/bim/:project_id()/converter/format/gfc2ifc/create",
    meta: index1sN5eeY304Meta || {},
    alias: index1sN5eeY304Meta?.alias || [],
    redirect: index1sN5eeY304Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/converter/format/gfc2ifc/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexBVSNsM8jyhMeta?.name ?? "bim-project_id-converter-format-gfc2ifc-list",
    path: indexBVSNsM8jyhMeta?.path ?? "/bim/:project_id()/converter/format/gfc2ifc/list",
    meta: indexBVSNsM8jyhMeta || {},
    alias: indexBVSNsM8jyhMeta?.alias || [],
    redirect: indexBVSNsM8jyhMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/converter/format/gfc2ifc/list/index.vue").then(m => m.default || m)
  },
  {
    name: indexwUI9sQFq5TMeta?.name ?? "bim-project_id-converter-format",
    path: indexwUI9sQFq5TMeta?.path ?? "/bim/:project_id()/converter/format",
    meta: indexwUI9sQFq5TMeta || {},
    alias: indexwUI9sQFq5TMeta?.alias || [],
    redirect: indexwUI9sQFq5TMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/converter/format/index.vue").then(m => m.default || m)
  },
  {
    name: resultuM0zbhYVY0Meta?.name ?? "bim-project_id-converter-format-p3d2ifc-id-result",
    path: resultuM0zbhYVY0Meta?.path ?? "/bim/:project_id()/converter/format/p3d2ifc/:id()/result",
    meta: resultuM0zbhYVY0Meta || {},
    alias: resultuM0zbhYVY0Meta?.alias || [],
    redirect: resultuM0zbhYVY0Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/converter/format/p3d2ifc/[id]/result.vue").then(m => m.default || m)
  },
  {
    name: indexhAzTDWr4xEMeta?.name ?? "bim-project_id-converter-format-p3d2ifc-create",
    path: indexhAzTDWr4xEMeta?.path ?? "/bim/:project_id()/converter/format/p3d2ifc/create",
    meta: indexhAzTDWr4xEMeta || {},
    alias: indexhAzTDWr4xEMeta?.alias || [],
    redirect: indexhAzTDWr4xEMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/converter/format/p3d2ifc/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexy8j9IF2Ci8Meta?.name ?? "bim-project_id-converter-format-p3d2ifc-list",
    path: indexy8j9IF2Ci8Meta?.path ?? "/bim/:project_id()/converter/format/p3d2ifc/list",
    meta: indexy8j9IF2Ci8Meta || {},
    alias: indexy8j9IF2Ci8Meta?.alias || [],
    redirect: indexy8j9IF2Ci8Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/converter/format/p3d2ifc/list/index.vue").then(m => m.default || m)
  },
  {
    name: result8LZyUOsOotMeta?.name ?? "bim-project_id-converter-format-rvt2ifc-id-result",
    path: result8LZyUOsOotMeta?.path ?? "/bim/:project_id()/converter/format/rvt2ifc/:id()/result",
    meta: result8LZyUOsOotMeta || {},
    alias: result8LZyUOsOotMeta?.alias || [],
    redirect: result8LZyUOsOotMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/converter/format/rvt2ifc/[id]/result.vue").then(m => m.default || m)
  },
  {
    name: index35gqZbUNUEMeta?.name ?? "bim-project_id-converter-format-rvt2ifc-create",
    path: index35gqZbUNUEMeta?.path ?? "/bim/:project_id()/converter/format/rvt2ifc/create",
    meta: index35gqZbUNUEMeta || {},
    alias: index35gqZbUNUEMeta?.alias || [],
    redirect: index35gqZbUNUEMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/converter/format/rvt2ifc/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexXtnvwBEHRsMeta?.name ?? "bim-project_id-converter-format-rvt2ifc-list",
    path: indexXtnvwBEHRsMeta?.path ?? "/bim/:project_id()/converter/format/rvt2ifc/list",
    meta: indexXtnvwBEHRsMeta || {},
    alias: indexXtnvwBEHRsMeta?.alias || [],
    redirect: indexXtnvwBEHRsMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/converter/format/rvt2ifc/list/index.vue").then(m => m.default || m)
  },
  {
    name: indexm1mTMHnBZ3Meta?.name ?? "bim-project_id-explore-image",
    path: indexm1mTMHnBZ3Meta?.path ?? "/bim/:project_id()/explore/image",
    meta: indexm1mTMHnBZ3Meta || {},
    alias: indexm1mTMHnBZ3Meta?.alias || [],
    redirect: indexm1mTMHnBZ3Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/explore/image/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_9317UKRUDmZVMeta?.name ?? "bim-project_id-explore-model-id",
    path: _91id_9317UKRUDmZVMeta?.path ?? "/bim/:project_id()/explore/model/:id()",
    meta: _91id_9317UKRUDmZVMeta || {},
    alias: _91id_9317UKRUDmZVMeta?.alias || [],
    redirect: _91id_9317UKRUDmZVMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/explore/model/[id].vue").then(m => m.default || m)
  },
  {
    name: indexC4hJESJpqoMeta?.name ?? "bim-project_id-explore-model",
    path: indexC4hJESJpqoMeta?.path ?? "/bim/:project_id()/explore/model",
    meta: indexC4hJESJpqoMeta || {},
    alias: indexC4hJESJpqoMeta?.alias || [],
    redirect: indexC4hJESJpqoMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/explore/model/index.vue").then(m => m.default || m)
  },
  {
    name: componentEfDA4lbdRnMeta?.name ?? "bim-project_id-graphic-component",
    path: componentEfDA4lbdRnMeta?.path ?? "/bim/:project_id()/graphic/component",
    meta: componentEfDA4lbdRnMeta || {},
    alias: componentEfDA4lbdRnMeta?.alias || [],
    redirect: componentEfDA4lbdRnMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/graphic/component.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93HOwwXCPQa3Meta?.name ?? "bim-project_id-graphic-component-id",
    path: _91id_93HOwwXCPQa3Meta?.path ?? ":id()",
    meta: _91id_93HOwwXCPQa3Meta || {},
    alias: _91id_93HOwwXCPQa3Meta?.alias || [],
    redirect: _91id_93HOwwXCPQa3Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/graphic/component/[id].vue").then(m => m.default || m)
  },
  {
    name: listIhgW9vVl8vMeta?.name ?? "bim-project_id-graphic-component-list",
    path: listIhgW9vVl8vMeta?.path ?? "list",
    meta: listIhgW9vVl8vMeta || {},
    alias: listIhgW9vVl8vMeta?.alias || [],
    redirect: listIhgW9vVl8vMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/graphic/component/list.vue").then(m => m.default || m)
  },
  {
    name: trash7C19HCi9PrMeta?.name ?? "bim-project_id-graphic-component-trash",
    path: trash7C19HCi9PrMeta?.path ?? "trash",
    meta: trash7C19HCi9PrMeta || {},
    alias: trash7C19HCi9PrMeta?.alias || [],
    redirect: trash7C19HCi9PrMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/graphic/component/trash.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index5gcrJS5PugMeta?.name ?? "bim-project_id-graphic",
    path: index5gcrJS5PugMeta?.path ?? "/bim/:project_id()/graphic",
    meta: index5gcrJS5PugMeta || {},
    alias: index5gcrJS5PugMeta?.alias || [],
    redirect: index5gcrJS5PugMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/graphic/index.vue").then(m => m.default || m)
  },
  {
    name: modeltlgp3EAqs0Meta?.name ?? "bim-project_id-graphic-model",
    path: modeltlgp3EAqs0Meta?.path ?? "/bim/:project_id()/graphic/model",
    meta: modeltlgp3EAqs0Meta || {},
    alias: modeltlgp3EAqs0Meta?.alias || [],
    redirect: modeltlgp3EAqs0Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/graphic/model.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93q6fdaHiqGtMeta?.name ?? "bim-project_id-graphic-model-id",
    path: _91id_93q6fdaHiqGtMeta?.path ?? ":id()",
    meta: _91id_93q6fdaHiqGtMeta || {},
    alias: _91id_93q6fdaHiqGtMeta?.alias || [],
    redirect: _91id_93q6fdaHiqGtMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/graphic/model/[id].vue").then(m => m.default || m)
  },
  {
    name: listfwgWFuMYUTMeta?.name ?? "bim-project_id-graphic-model-list",
    path: listfwgWFuMYUTMeta?.path ?? "list",
    meta: listfwgWFuMYUTMeta || {},
    alias: listfwgWFuMYUTMeta?.alias || [],
    redirect: listfwgWFuMYUTMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/graphic/model/list.vue").then(m => m.default || m)
  },
  {
    name: trashOW0n4dOBA0Meta?.name ?? "bim-project_id-graphic-model-trash",
    path: trashOW0n4dOBA0Meta?.path ?? "trash",
    meta: trashOW0n4dOBA0Meta || {},
    alias: trashOW0n4dOBA0Meta?.alias || [],
    redirect: trashOW0n4dOBA0Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/graphic/model/trash.vue").then(m => m.default || m)
  }
]
  },
  {
    name: home2ftkLMC5ZYMeta?.name ?? "bim-project_id-overview-home",
    path: home2ftkLMC5ZYMeta?.path ?? "/bim/:project_id()/overview/home",
    meta: home2ftkLMC5ZYMeta || {},
    alias: home2ftkLMC5ZYMeta?.alias || [],
    redirect: home2ftkLMC5ZYMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/yp231519/pages/bim/[project_id]/overview/home.vue").then(m => m.default || m)
  },
  {
    name: indexqDA2o7YiNDMeta?.name ?? "bim-project_id-overview",
    path: indexqDA2o7YiNDMeta?.path ?? "/bim/:project_id()/overview",
    meta: indexqDA2o7YiNDMeta || {},
    alias: indexqDA2o7YiNDMeta?.alias || [],
    redirect: indexqDA2o7YiNDMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/yp231519/pages/bim/[project_id]/overview/index.vue").then(m => m.default || m)
  },
  {
    name: indexOld7XJWHUzMeta?.name ?? "bim-project_id-process-extract-create",
    path: indexOld7XJWHUzMeta?.path ?? "/bim/:project_id()/process/extract/create",
    meta: indexOld7XJWHUzMeta || {},
    alias: indexOld7XJWHUzMeta?.alias || [],
    redirect: indexOld7XJWHUzMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/process/extract/create/index.vue").then(m => m.default || m)
  },
  {
    name: indexz2GOEj8PFQMeta?.name ?? "bim-project_id-process-extract",
    path: indexz2GOEj8PFQMeta?.path ?? "/bim/:project_id()/process/extract",
    meta: indexz2GOEj8PFQMeta || {},
    alias: indexz2GOEj8PFQMeta?.alias || [],
    redirect: indexz2GOEj8PFQMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/process/extract/index.vue").then(m => m.default || m)
  },
  {
    name: indexRDvaKZr95nMeta?.name ?? "bim-project_id-process-merge-create",
    path: indexRDvaKZr95nMeta?.path ?? "/bim/:project_id()/process/merge/create",
    meta: indexRDvaKZr95nMeta || {},
    alias: indexRDvaKZr95nMeta?.alias || [],
    redirect: indexRDvaKZr95nMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/process/merge/create/index.vue").then(m => m.default || m)
  },
  {
    name: index9GjSGzgZxcMeta?.name ?? "bim-project_id-process-merge",
    path: index9GjSGzgZxcMeta?.path ?? "/bim/:project_id()/process/merge",
    meta: index9GjSGzgZxcMeta || {},
    alias: index9GjSGzgZxcMeta?.alias || [],
    redirect: index9GjSGzgZxcMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/process/merge/index.vue").then(m => m.default || m)
  },
  {
    name: devicexrbbGmr6wxMeta?.name ?? "bim-project_id-programme-device",
    path: devicexrbbGmr6wxMeta?.path ?? "/bim/:project_id()/programme/device",
    meta: devicexrbbGmr6wxMeta || {},
    alias: devicexrbbGmr6wxMeta?.alias || [],
    redirect: devicexrbbGmr6wxMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/programme/device.vue").then(m => m.default || m),
    children: [
  {
    name: _91id_93xN9dkHZTniMeta?.name ?? "bim-project_id-programme-device-id",
    path: _91id_93xN9dkHZTniMeta?.path ?? ":id()",
    meta: _91id_93xN9dkHZTniMeta || {},
    alias: _91id_93xN9dkHZTniMeta?.alias || [],
    redirect: _91id_93xN9dkHZTniMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/programme/device/[id].vue").then(m => m.default || m)
  },
  {
    name: SelectTree3oemXPk9ThMeta?.name ?? "bim-project_id-programme-device-components-SelectTree",
    path: SelectTree3oemXPk9ThMeta?.path ?? "components/SelectTree",
    meta: SelectTree3oemXPk9ThMeta || {},
    alias: SelectTree3oemXPk9ThMeta?.alias || [],
    redirect: SelectTree3oemXPk9ThMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/programme/device/components/SelectTree.vue").then(m => m.default || m)
  },
  {
    name: useCreateDesignPlanxnMppPU2GOMeta?.name ?? "bim-project_id-programme-device-composables-useCreateDesignPlan",
    path: useCreateDesignPlanxnMppPU2GOMeta?.path ?? "composables/useCreateDesignPlan",
    meta: useCreateDesignPlanxnMppPU2GOMeta || {},
    alias: useCreateDesignPlanxnMppPU2GOMeta?.alias || [],
    redirect: useCreateDesignPlanxnMppPU2GOMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/programme/device/composables/useCreateDesignPlan.ts").then(m => m.default || m)
  },
  {
    name: machinefHx0M4b6F9Meta?.name ?? "bim-project_id-programme-device-machine",
    path: machinefHx0M4b6F9Meta?.path ?? "machine",
    meta: machinefHx0M4b6F9Meta || {},
    alias: machinefHx0M4b6F9Meta?.alias || [],
    redirect: machinefHx0M4b6F9Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/programme/device/machine.vue").then(m => m.default || m)
  },
  {
    name: standardxMT5nhiEClMeta?.name ?? "bim-project_id-programme-device-standard",
    path: standardxMT5nhiEClMeta?.path ?? "standard",
    meta: standardxMT5nhiEClMeta || {},
    alias: standardxMT5nhiEClMeta?.alias || [],
    redirect: standardxMT5nhiEClMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/programme/device/standard.vue").then(m => m.default || m)
  },
  {
    name: verticalyTJxrVr8URMeta?.name ?? "bim-project_id-programme-device-vertical",
    path: verticalyTJxrVr8URMeta?.path ?? "vertical",
    meta: verticalyTJxrVr8URMeta || {},
    alias: verticalyTJxrVr8URMeta?.alias || [],
    redirect: verticalyTJxrVr8URMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/programme/device/vertical.vue").then(m => m.default || m)
  }
]
  },
  {
    name: uploadAuklDwJO5EMeta?.name ?? "bim-project_id-transmission-upload",
    path: uploadAuklDwJO5EMeta?.path ?? "/bim/:project_id()/transmission/upload",
    meta: uploadAuklDwJO5EMeta || {},
    alias: uploadAuklDwJO5EMeta?.alias || [],
    redirect: uploadAuklDwJO5EMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/transmission/upload.vue").then(m => m.default || m),
    children: [
  {
    name: pluginhuFA8kM6wbMeta?.name ?? "bim-project_id-transmission-upload-plugin",
    path: pluginhuFA8kM6wbMeta?.path ?? "plugin",
    meta: pluginhuFA8kM6wbMeta || {},
    alias: pluginhuFA8kM6wbMeta?.alias || [],
    redirect: pluginhuFA8kM6wbMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/[project_id]/transmission/upload/plugin.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexLek8dHSFZvMeta?.name ?? "bim",
    path: indexLek8dHSFZvMeta?.path ?? "/bim",
    meta: indexLek8dHSFZvMeta || {},
    alias: indexLek8dHSFZvMeta?.alias || [],
    redirect: indexLek8dHSFZvMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93E9oyTJwDwlMeta?.name ?? "bim-todos-id",
    path: _91id_93E9oyTJwDwlMeta?.path ?? "/bim/todos/:id()",
    meta: _91id_93E9oyTJwDwlMeta || {},
    alias: _91id_93E9oyTJwDwlMeta?.alias || [],
    redirect: _91id_93E9oyTJwDwlMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/todos/[id].vue").then(m => m.default || m)
  },
  {
    name: indexkwKOewMcPBMeta?.name ?? "bim-todos",
    path: indexkwKOewMcPBMeta?.path ?? "/bim/todos",
    meta: indexkwKOewMcPBMeta || {},
    alias: indexkwKOewMcPBMeta?.alias || [],
    redirect: indexkwKOewMcPBMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/todos/index.vue").then(m => m.default || m)
  },
  {
    name: viewerk8MQyDcIdvMeta?.name ?? "bim-viewer",
    path: viewerk8MQyDcIdvMeta?.path ?? "/bim/viewer",
    meta: viewerk8MQyDcIdvMeta || {},
    alias: viewerk8MQyDcIdvMeta?.alias || [],
    redirect: viewerk8MQyDcIdvMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bim/pages/bim/viewer.vue").then(m => m.default || m)
  },
  {
    name: _91content_93fqX7tot7EEMeta?.name ?? "blog-content",
    path: _91content_93fqX7tot7EEMeta?.path ?? "/blog/:content()",
    meta: _91content_93fqX7tot7EEMeta || {},
    alias: _91content_93fqX7tot7EEMeta?.alias || [],
    redirect: _91content_93fqX7tot7EEMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/apps/platform/pages/blog/[content].vue").then(m => m.default || m)
  },
  {
    name: indexsoNOP787EMMeta?.name ?? "blog",
    path: indexsoNOP787EMMeta?.path ?? "/blog",
    meta: indexsoNOP787EMMeta || {},
    alias: indexsoNOP787EMMeta?.alias || [],
    redirect: indexsoNOP787EMMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/apps/platform/pages/blog/index.vue").then(m => m.default || m)
  },
  {
    name: indexpNSycpOte6Meta?.name ?? "bom-project_id-material-list-admin",
    path: indexpNSycpOte6Meta?.path ?? "/bom/:project_id()/material-list/admin",
    meta: indexpNSycpOte6Meta || {},
    alias: indexpNSycpOte6Meta?.alias || [],
    redirect: indexpNSycpOte6Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bom/pages/bom/[project_id]/material-list/admin/index.vue").then(m => m.default || m)
  },
  {
    name: index7X9EC2Fl2bMeta?.name ?? "bom-project_id-material-list",
    path: index7X9EC2Fl2bMeta?.path ?? "/bom/:project_id()/material-list",
    meta: index7X9EC2Fl2bMeta || {},
    alias: index7X9EC2Fl2bMeta?.alias || [],
    redirect: index7X9EC2Fl2bMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bom/pages/bom/[project_id]/material-list/index.vue").then(m => m.default || m)
  },
  {
    name: indexYSVJXC15mRMeta?.name ?? "bom",
    path: indexYSVJXC15mRMeta?.path ?? "/bom",
    meta: indexYSVJXC15mRMeta || {},
    alias: indexYSVJXC15mRMeta?.alias || [],
    redirect: indexYSVJXC15mRMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/bom/pages/bom/index.vue").then(m => m.default || m)
  },
  {
    name: _91key_93KH24EDjgLbMeta?.name ?? "catalog-admin-catalogs-key",
    path: _91key_93KH24EDjgLbMeta?.path ?? "/catalog/admin/catalogs/:key()",
    meta: _91key_93KH24EDjgLbMeta || {},
    alias: _91key_93KH24EDjgLbMeta?.alias || [],
    redirect: _91key_93KH24EDjgLbMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/catalog/pages/catalog/admin/catalogs/[key].vue").then(m => m.default || m)
  },
  {
    name: create3nRAzEKohKMeta?.name ?? "catalog-admin-catalogs-create",
    path: create3nRAzEKohKMeta?.path ?? "/catalog/admin/catalogs/create",
    meta: create3nRAzEKohKMeta || {},
    alias: create3nRAzEKohKMeta?.alias || [],
    redirect: create3nRAzEKohKMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/catalog/pages/catalog/admin/catalogs/create.vue").then(m => m.default || m)
  },
  {
    name: index3NadgppKzmMeta?.name ?? "catalog-admin-catalogs",
    path: index3NadgppKzmMeta?.path ?? "/catalog/admin/catalogs",
    meta: index3NadgppKzmMeta || {},
    alias: index3NadgppKzmMeta?.alias || [],
    redirect: index3NadgppKzmMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/catalog/pages/catalog/admin/catalogs/index.vue").then(m => m.default || m)
  },
  {
    name: indexTChmTq8HbWMeta?.name ?? "catalog-admin",
    path: indexTChmTq8HbWMeta?.path ?? "/catalog/admin",
    meta: indexTChmTq8HbWMeta || {},
    alias: indexTChmTq8HbWMeta?.alias || [],
    redirect: indexTChmTq8HbWMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/catalog/pages/catalog/admin/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93lXP7u8GXSOMeta?.name ?? "catalog-admin-todos-id",
    path: _91id_93lXP7u8GXSOMeta?.path ?? "/catalog/admin/todos/:id()",
    meta: _91id_93lXP7u8GXSOMeta || {},
    alias: _91id_93lXP7u8GXSOMeta?.alias || [],
    redirect: _91id_93lXP7u8GXSOMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/catalog/pages/catalog/admin/todos/[id].vue").then(m => m.default || m)
  },
  {
    name: createGkT8Gv8NAaMeta?.name ?? "catalog-admin-todos-create",
    path: createGkT8Gv8NAaMeta?.path ?? "/catalog/admin/todos/create",
    meta: createGkT8Gv8NAaMeta || {},
    alias: createGkT8Gv8NAaMeta?.alias || [],
    redirect: createGkT8Gv8NAaMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/catalog/pages/catalog/admin/todos/create.vue").then(m => m.default || m)
  },
  {
    name: indexfKK4GvtgE5Meta?.name ?? "catalog-admin-todos",
    path: indexfKK4GvtgE5Meta?.path ?? "/catalog/admin/todos",
    meta: indexfKK4GvtgE5Meta || {},
    alias: indexfKK4GvtgE5Meta?.alias || [],
    redirect: indexfKK4GvtgE5Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/catalog/pages/catalog/admin/todos/index.vue").then(m => m.default || m)
  },
  {
    name: edituCBsV6G8dBMeta?.name ?? "catalog-catalog-edit",
    path: edituCBsV6G8dBMeta?.path ?? "/catalog/catalog/edit",
    meta: edituCBsV6G8dBMeta || {},
    alias: edituCBsV6G8dBMeta?.alias || [],
    redirect: edituCBsV6G8dBMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/catalog/pages/catalog/catalog/edit.vue").then(m => m.default || m)
  },
  {
    name: listFN6XL3v5U8Meta?.name ?? "catalog-catalog-list",
    path: listFN6XL3v5U8Meta?.path ?? "/catalog/catalog/list",
    meta: listFN6XL3v5U8Meta || {},
    alias: listFN6XL3v5U8Meta?.alias || [],
    redirect: listFN6XL3v5U8Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/catalog/pages/catalog/catalog/list.vue").then(m => m.default || m)
  },
  {
    name: actionYCmFycXwzwMeta?.name ?? "flow-project_id-approve-mine-id-action",
    path: actionYCmFycXwzwMeta?.path ?? "/flow/:project_id()/approve/mine/:id()/action",
    meta: actionYCmFycXwzwMeta || {},
    alias: actionYCmFycXwzwMeta?.alias || [],
    redirect: actionYCmFycXwzwMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/flow/pages/flow/[project_id]/approve/mine/[id]/action.vue").then(m => m.default || m)
  },
  {
    name: detail0jLnIXXCrfMeta?.name ?? "flow-project_id-approve-mine-id-detail",
    path: detail0jLnIXXCrfMeta?.path ?? "/flow/:project_id()/approve/mine/:id()/detail",
    meta: detail0jLnIXXCrfMeta || {},
    alias: detail0jLnIXXCrfMeta?.alias || [],
    redirect: detail0jLnIXXCrfMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/flow/pages/flow/[project_id]/approve/mine/[id]/detail.vue").then(m => m.default || m)
  },
  {
    name: indexhX1KGdhVGqMeta?.name ?? "flow-project_id-approve-mine",
    path: indexhX1KGdhVGqMeta?.path ?? "/flow/:project_id()/approve/mine",
    meta: indexhX1KGdhVGqMeta || {},
    alias: indexhX1KGdhVGqMeta?.alias || [],
    redirect: indexhX1KGdhVGqMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/flow/pages/flow/[project_id]/approve/mine/index.vue").then(m => m.default || m)
  },
  {
    name: recordIaJ3QabtiFMeta?.name ?? "flow-project_id-approve-mine-record",
    path: recordIaJ3QabtiFMeta?.path ?? "/flow/:project_id()/approve/mine/record",
    meta: recordIaJ3QabtiFMeta || {},
    alias: recordIaJ3QabtiFMeta?.alias || [],
    redirect: recordIaJ3QabtiFMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/flow/pages/flow/[project_id]/approve/mine/record.vue").then(m => m.default || m),
    children: [
  {
    name: doneKwZsNF31X1Meta?.name ?? "flow-project_id-approve-mine-record-done",
    path: doneKwZsNF31X1Meta?.path ?? "done",
    meta: doneKwZsNF31X1Meta || {},
    alias: doneKwZsNF31X1Meta?.alias || [],
    redirect: doneKwZsNF31X1Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/flow/pages/flow/[project_id]/approve/mine/record/done.vue").then(m => m.default || m)
  },
  {
    name: receiveH3shQZzLUkMeta?.name ?? "flow-project_id-approve-mine-record-receive",
    path: receiveH3shQZzLUkMeta?.path ?? "receive",
    meta: receiveH3shQZzLUkMeta || {},
    alias: receiveH3shQZzLUkMeta?.alias || [],
    redirect: receiveH3shQZzLUkMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/flow/pages/flow/[project_id]/approve/mine/record/receive.vue").then(m => m.default || m)
  },
  {
    name: startgfM9H7x4xUMeta?.name ?? "flow-project_id-approve-mine-record-start",
    path: startgfM9H7x4xUMeta?.path ?? "start",
    meta: startgfM9H7x4xUMeta || {},
    alias: startgfM9H7x4xUMeta?.alias || [],
    redirect: startgfM9H7x4xUMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/flow/pages/flow/[project_id]/approve/mine/record/start.vue").then(m => m.default || m)
  },
  {
    name: undoiDei0UizASMeta?.name ?? "flow-project_id-approve-mine-record-undo",
    path: undoiDei0UizASMeta?.path ?? "undo",
    meta: undoiDei0UizASMeta || {},
    alias: undoiDei0UizASMeta?.alias || [],
    redirect: undoiDei0UizASMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/flow/pages/flow/[project_id]/approve/mine/record/undo.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_93x1HlB15pDuMeta?.name ?? "flow-project_id-manage-approvals-id",
    path: _91id_93x1HlB15pDuMeta?.path ?? "/flow/:project_id()/manage/approvals/:id()",
    meta: _91id_93x1HlB15pDuMeta || {},
    alias: _91id_93x1HlB15pDuMeta?.alias || [],
    redirect: _91id_93x1HlB15pDuMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/flow/pages/flow/[project_id]/manage/approvals/[id].vue").then(m => m.default || m),
    children: [
  {
    name: baseRppgt7L49fMeta?.name ?? "flow-project_id-manage-approvals-id-base",
    path: baseRppgt7L49fMeta?.path ?? "base",
    meta: baseRppgt7L49fMeta || {},
    alias: baseRppgt7L49fMeta?.alias || [],
    redirect: baseRppgt7L49fMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/flow/pages/flow/[project_id]/manage/approvals/[id]/base.vue").then(m => m.default || m)
  },
  {
    name: formysAcIfbb0zMeta?.name ?? "flow-project_id-manage-approvals-id-form",
    path: formysAcIfbb0zMeta?.path ?? "form",
    meta: formysAcIfbb0zMeta || {},
    alias: formysAcIfbb0zMeta?.alias || [],
    redirect: formysAcIfbb0zMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/flow/pages/flow/[project_id]/manage/approvals/[id]/form.vue").then(m => m.default || m)
  },
  {
    name: nodet3JCF4KEpUMeta?.name ?? "flow-project_id-manage-approvals-id-node",
    path: nodet3JCF4KEpUMeta?.path ?? "node",
    meta: nodet3JCF4KEpUMeta || {},
    alias: nodet3JCF4KEpUMeta?.alias || [],
    redirect: nodet3JCF4KEpUMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/flow/pages/flow/[project_id]/manage/approvals/[id]/node.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexKoWAEFo3yCMeta?.name ?? "flow-project_id-manage-approvals",
    path: indexKoWAEFo3yCMeta?.path ?? "/flow/:project_id()/manage/approvals",
    meta: indexKoWAEFo3yCMeta || {},
    alias: indexKoWAEFo3yCMeta?.alias || [],
    redirect: indexKoWAEFo3yCMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/flow/pages/flow/[project_id]/manage/approvals/index.vue").then(m => m.default || m)
  },
  {
    name: indexnUrnf2c3SCMeta?.name ?? "flow-project_id-manage",
    path: indexnUrnf2c3SCMeta?.path ?? "/flow/:project_id()/manage",
    meta: indexnUrnf2c3SCMeta || {},
    alias: indexnUrnf2c3SCMeta?.alias || [],
    redirect: indexnUrnf2c3SCMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/flow/pages/flow/[project_id]/manage/index.vue").then(m => m.default || m)
  },
  {
    name: actionEGj9VluvzVMeta?.name ?? "flow-project_id-manage-list-id-action",
    path: actionEGj9VluvzVMeta?.path ?? "/flow/:project_id()/manage/list/:id()/action",
    meta: actionEGj9VluvzVMeta || {},
    alias: actionEGj9VluvzVMeta?.alias || [],
    redirect: actionEGj9VluvzVMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/flow/pages/flow/[project_id]/manage/list/[id]/action.vue").then(m => m.default || m)
  },
  {
    name: index7qoS4Q6xyBMeta?.name ?? "flow-project_id-manage-list",
    path: index7qoS4Q6xyBMeta?.path ?? "/flow/:project_id()/manage/list",
    meta: index7qoS4Q6xyBMeta || {},
    alias: index7qoS4Q6xyBMeta?.alias || [],
    redirect: index7qoS4Q6xyBMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/flow/pages/flow/[project_id]/manage/list/index.vue").then(m => m.default || m)
  },
  {
    name: indexTVMl7TT0EcMeta?.name ?? "index",
    path: indexTVMl7TT0EcMeta?.path ?? "/",
    meta: indexTVMl7TT0EcMeta || {},
    alias: indexTVMl7TT0EcMeta?.alias || [],
    redirect: indexTVMl7TT0EcMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/apps/platform/pages/index.vue").then(m => m.default || m)
  },
  {
    name: indexV0nKPMDIv1Meta?.name ?? "lifecycle-project_id-ifc",
    path: indexV0nKPMDIv1Meta?.path ?? "/lifecycle/:project_id()/ifc",
    meta: indexV0nKPMDIv1Meta || {},
    alias: indexV0nKPMDIv1Meta?.alias || [],
    redirect: indexV0nKPMDIv1Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/lifecycle/pages/lifecycle/[project_id]/ifc/index.vue").then(m => m.default || m)
  },
  {
    name: modelsBDpQOiVLKIMeta?.name ?? "lifecycle-project_id-models",
    path: modelsBDpQOiVLKIMeta?.path ?? "/lifecycle/:project_id()/models",
    meta: modelsBDpQOiVLKIMeta || {},
    alias: modelsBDpQOiVLKIMeta?.alias || [],
    redirect: modelsBDpQOiVLKIMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/lifecycle/pages/lifecycle/[project_id]/models.vue").then(m => m.default || m)
  },
  {
    name: indexfsJgHjtm2rMeta?.name ?? "lifecycle",
    path: indexfsJgHjtm2rMeta?.path ?? "/lifecycle",
    meta: indexfsJgHjtm2rMeta || {},
    alias: indexfsJgHjtm2rMeta?.alias || [],
    redirect: indexfsJgHjtm2rMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/lifecycle/pages/lifecycle/index.vue").then(m => m.default || m)
  },
  {
    name: login_46authjskT5R91vjoVMeta?.name ?? "login.authjs",
    path: login_46authjskT5R91vjoVMeta?.path ?? "/login.authjs",
    meta: login_46authjskT5R91vjoVMeta || {},
    alias: login_46authjskT5R91vjoVMeta?.alias || [],
    redirect: login_46authjskT5R91vjoVMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/framework/pages/login.authjs.vue").then(m => m.default || m)
  },
  {
    name: loginwhOoGUg0VlMeta?.name ?? "login",
    path: loginwhOoGUg0VlMeta?.path ?? "/login",
    meta: loginwhOoGUg0VlMeta || {},
    alias: loginwhOoGUg0VlMeta?.alias || [],
    redirect: loginwhOoGUg0VlMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/framework/pages/login.vue").then(m => m.default || m)
  },
  {
    name: MainLayoutlSne8gS6efMeta?.name ?? "MainLayout",
    path: MainLayoutlSne8gS6efMeta?.path ?? "/MainLayout",
    meta: MainLayoutlSne8gS6efMeta || {},
    alias: MainLayoutlSne8gS6efMeta?.alias || [],
    redirect: MainLayoutlSne8gS6efMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/MainLayout.vue").then(m => m.default || m)
  },
  {
    name: index72V1YteB04Meta?.name ?? "monitor-project_id-map",
    path: index72V1YteB04Meta?.path ?? "/monitor/:project_id()/map",
    meta: index72V1YteB04Meta || {},
    alias: index72V1YteB04Meta?.alias || [],
    redirect: index72V1YteB04Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/monitor/pages/monitor/[project_id]/map/index.vue").then(m => m.default || m)
  },
  {
    name: indexEcBGDQ4Zh1Meta?.name ?? "monitor-project_id-operate",
    path: indexEcBGDQ4Zh1Meta?.path ?? "/monitor/:project_id()/operate",
    meta: indexEcBGDQ4Zh1Meta || {},
    alias: indexEcBGDQ4Zh1Meta?.alias || [],
    redirect: indexEcBGDQ4Zh1Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/monitor/pages/monitor/[project_id]/operate/index.vue").then(m => m.default || m)
  },
  {
    name: indexUU6NkIRntHMeta?.name ?? "monitor-project_id-overview",
    path: indexUU6NkIRntHMeta?.path ?? "/monitor/:project_id()/overview",
    meta: indexUU6NkIRntHMeta || {},
    alias: indexUU6NkIRntHMeta?.alias || [],
    redirect: indexUU6NkIRntHMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/monitor/pages/monitor/[project_id]/overview/index.vue").then(m => m.default || m)
  },
  {
    name: indexuGADjSi4WjMeta?.name ?? "monitor-project_id-players",
    path: indexuGADjSi4WjMeta?.path ?? "/monitor/:project_id()/players",
    meta: indexuGADjSi4WjMeta || {},
    alias: indexuGADjSi4WjMeta?.alias || [],
    redirect: indexuGADjSi4WjMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/monitor/pages/monitor/[project_id]/players/index.vue").then(m => m.default || m)
  },
  {
    name: indexsyDI8tHxYkMeta?.name ?? "monitor-project_id-receivers",
    path: indexsyDI8tHxYkMeta?.path ?? "/monitor/:project_id()/receivers",
    meta: indexsyDI8tHxYkMeta || {},
    alias: indexsyDI8tHxYkMeta?.alias || [],
    redirect: indexsyDI8tHxYkMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/monitor/pages/monitor/[project_id]/receivers/index.vue").then(m => m.default || m)
  },
  {
    name: index1CAtiak6h1Meta?.name ?? "om",
    path: index1CAtiak6h1Meta?.path ?? "/om",
    meta: index1CAtiak6h1Meta || {},
    alias: index1CAtiak6h1Meta?.alias || [],
    redirect: index1CAtiak6h1Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/om/pages/om/index.vue").then(m => m.default || m)
  },
  {
    name: indexZypG1sNlHBMeta?.name ?? "om-orgs",
    path: indexZypG1sNlHBMeta?.path ?? "/om/orgs",
    meta: indexZypG1sNlHBMeta || {},
    alias: indexZypG1sNlHBMeta?.alias || [],
    redirect: indexZypG1sNlHBMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/om/pages/om/orgs/index.vue").then(m => m.default || m)
  },
  {
    name: _91oid_93q8AuJNBQobMeta?.name ?? "organization-oid",
    path: _91oid_93q8AuJNBQobMeta?.path ?? "/organization/:oid()",
    meta: _91oid_93q8AuJNBQobMeta || {},
    alias: _91oid_93q8AuJNBQobMeta?.alias || [],
    redirect: _91oid_93q8AuJNBQobMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/organization/pages/organization/[oid].vue").then(m => m.default || m),
    children: [
  {
    name: indexR6XmKiCY5VMeta?.name ?? "organization-oid-access-auth",
    path: indexR6XmKiCY5VMeta?.path ?? "access/auth",
    meta: indexR6XmKiCY5VMeta || {},
    alias: indexR6XmKiCY5VMeta?.alias || [],
    redirect: indexR6XmKiCY5VMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/organization/pages/organization/[oid]/access/auth/index.vue").then(m => m.default || m)
  },
  {
    name: indexhHwZ7Lrj16Meta?.name ?? "organization-oid-access-role",
    path: indexhHwZ7Lrj16Meta?.path ?? "access/role",
    meta: indexhHwZ7Lrj16Meta || {},
    alias: indexhHwZ7Lrj16Meta?.alias || [],
    redirect: indexhHwZ7Lrj16Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/organization/pages/organization/[oid]/access/role/index.vue").then(m => m.default || m)
  },
  {
    name: indexbUIMLAtXF2Meta?.name ?? "organization-oid-culture-appearance",
    path: indexbUIMLAtXF2Meta?.path ?? "culture/appearance",
    meta: indexbUIMLAtXF2Meta || {},
    alias: indexbUIMLAtXF2Meta?.alias || [],
    redirect: indexbUIMLAtXF2Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/organization/pages/organization/[oid]/culture/appearance/index.vue").then(m => m.default || m)
  },
  {
    name: index9yv3UANk2gMeta?.name ?? "organization-oid-culture-dict",
    path: index9yv3UANk2gMeta?.path ?? "culture/dict",
    meta: index9yv3UANk2gMeta || {},
    alias: index9yv3UANk2gMeta?.alias || [],
    redirect: index9yv3UANk2gMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/organization/pages/organization/[oid]/culture/dict/index.vue").then(m => m.default || m)
  },
  {
    name: indexO823mEDVbcMeta?.name ?? "organization-oid-culture-field",
    path: indexO823mEDVbcMeta?.path ?? "culture/field",
    meta: indexO823mEDVbcMeta || {},
    alias: indexO823mEDVbcMeta?.alias || [],
    redirect: indexO823mEDVbcMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/organization/pages/organization/[oid]/culture/field/index.vue").then(m => m.default || m)
  },
  {
    name: _91employee_oid_93bRQMQw3oJdMeta?.name ?? "organization-oid-employee-employee_oid",
    path: _91employee_oid_93bRQMQw3oJdMeta?.path ?? "employee/:employee_oid()",
    meta: _91employee_oid_93bRQMQw3oJdMeta || {},
    alias: _91employee_oid_93bRQMQw3oJdMeta?.alias || [],
    redirect: _91employee_oid_93bRQMQw3oJdMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/organization/pages/organization/[oid]/employee/[employee_oid].vue").then(m => m.default || m),
    children: [
  {
    name: apiauthtvasEVOPrdMeta?.name ?? "organization-oid-employee-employee_oid-apiauth",
    path: apiauthtvasEVOPrdMeta?.path ?? "apiauth",
    meta: apiauthtvasEVOPrdMeta || {},
    alias: apiauthtvasEVOPrdMeta?.alias || [],
    redirect: apiauthtvasEVOPrdMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/organization/pages/organization/[oid]/employee/[employee_oid]/apiauth.vue").then(m => m.default || m)
  },
  {
    name: basicFP3G04jQCqMeta?.name ?? "organization-oid-employee-employee_oid-basic",
    path: basicFP3G04jQCqMeta?.path ?? "basic",
    meta: basicFP3G04jQCqMeta || {},
    alias: basicFP3G04jQCqMeta?.alias || [],
    redirect: basicFP3G04jQCqMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/organization/pages/organization/[oid]/employee/[employee_oid]/basic.vue").then(m => m.default || m)
  }
]
  },
  {
    name: infoOYMwVztOSeMeta?.name ?? "organization-oid-info",
    path: infoOYMwVztOSeMeta?.path ?? "info",
    meta: infoOYMwVztOSeMeta || {},
    alias: infoOYMwVztOSeMeta?.alias || [],
    redirect: infoOYMwVztOSeMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/organization/pages/organization/[oid]/info.vue").then(m => m.default || m),
    children: [
  {
    name: basicZGMqpEwxkAMeta?.name ?? "organization-oid-info-basic",
    path: basicZGMqpEwxkAMeta?.path ?? "basic",
    meta: basicZGMqpEwxkAMeta || {},
    alias: basicZGMqpEwxkAMeta?.alias || [],
    redirect: basicZGMqpEwxkAMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/organization/pages/organization/[oid]/info/basic.vue").then(m => m.default || m)
  },
  {
    name: mapmXBTy4PEWFMeta?.name ?? "organization-oid-info-map",
    path: mapmXBTy4PEWFMeta?.path ?? "map",
    meta: mapmXBTy4PEWFMeta || {},
    alias: mapmXBTy4PEWFMeta?.alias || [],
    redirect: mapmXBTy4PEWFMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/organization/pages/organization/[oid]/info/map.vue").then(m => m.default || m)
  }
]
  },
  {
    name: index4wv2tomBi7Meta?.name ?? "organization-oid-structure-department",
    path: index4wv2tomBi7Meta?.path ?? "structure/department",
    meta: index4wv2tomBi7Meta || {},
    alias: index4wv2tomBi7Meta?.alias || [],
    redirect: index4wv2tomBi7Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/organization/pages/organization/[oid]/structure/department/index.vue").then(m => m.default || m)
  },
  {
    name: indexyGnIc7SgNIMeta?.name ?? "organization-oid-structure-employee",
    path: indexyGnIc7SgNIMeta?.path ?? "structure/employee",
    meta: indexyGnIc7SgNIMeta || {},
    alias: indexyGnIc7SgNIMeta?.alias || [],
    redirect: indexyGnIc7SgNIMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/organization/pages/organization/[oid]/structure/employee/index.vue").then(m => m.default || m)
  },
  {
    name: indexOa7rqZOXocMeta?.name ?? "organization-oid-structure-group",
    path: indexOa7rqZOXocMeta?.path ?? "structure/group",
    meta: indexOa7rqZOXocMeta || {},
    alias: indexOa7rqZOXocMeta?.alias || [],
    redirect: indexOa7rqZOXocMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/organization/pages/organization/[oid]/structure/group/index.vue").then(m => m.default || m)
  },
  {
    name: indexfnEXWcWyUsMeta?.name ?? "organization-oid-structure-v1-department",
    path: indexfnEXWcWyUsMeta?.path ?? "structure/v1/department",
    meta: indexfnEXWcWyUsMeta || {},
    alias: indexfnEXWcWyUsMeta?.alias || [],
    redirect: indexfnEXWcWyUsMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/organization/pages/organization/[oid]/structure/v1/department/index.vue").then(m => m.default || m)
  },
  {
    name: employeeRv5ALz4L07Meta?.name ?? "organization-oid-structure-v1-employee",
    path: employeeRv5ALz4L07Meta?.path ?? "structure/v1/employee",
    meta: employeeRv5ALz4L07Meta || {},
    alias: employeeRv5ALz4L07Meta?.alias || [],
    redirect: employeeRv5ALz4L07Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/organization/pages/organization/[oid]/structure/v1/employee.vue").then(m => m.default || m),
    children: [
  {
    name: normalWSn9y8X8CLMeta?.name ?? "organization-oid-structure-v1-employee-normal",
    path: normalWSn9y8X8CLMeta?.path ?? "normal",
    meta: normalWSn9y8X8CLMeta || {},
    alias: normalWSn9y8X8CLMeta?.alias || [],
    redirect: normalWSn9y8X8CLMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/organization/pages/organization/[oid]/structure/v1/employee/normal.vue").then(m => m.default || m)
  }
]
  }
]
  },
  {
    name: indexozPzMr66fFMeta?.name ?? "organization",
    path: indexozPzMr66fFMeta?.path ?? "/organization",
    meta: indexozPzMr66fFMeta || {},
    alias: indexozPzMr66fFMeta?.alias || [],
    redirect: indexozPzMr66fFMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/organization/pages/organization/index.vue").then(m => m.default || m)
  },
  {
    name: indexobsRGJgVqjMeta?.name ?? "pm-admin-catalog",
    path: indexobsRGJgVqjMeta?.path ?? "/pm/admin/catalog",
    meta: indexobsRGJgVqjMeta || {},
    alias: indexobsRGJgVqjMeta?.alias || [],
    redirect: indexobsRGJgVqjMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/pm/pages/pm/admin/catalog/index.vue").then(m => m.default || m)
  },
  {
    name: org1eYjU1dv3wMeta?.name ?? "pm-admin-collaboration-org",
    path: org1eYjU1dv3wMeta?.path ?? "/pm/admin/collaboration/org",
    meta: org1eYjU1dv3wMeta || {},
    alias: org1eYjU1dv3wMeta?.alias || [],
    redirect: org1eYjU1dv3wMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/pm/pages/pm/admin/collaboration/org.vue").then(m => m.default || m)
  },
  {
    name: userLaKFke2gGpMeta?.name ?? "pm-admin-collaboration-user",
    path: userLaKFke2gGpMeta?.path ?? "/pm/admin/collaboration/user",
    meta: userLaKFke2gGpMeta || {},
    alias: userLaKFke2gGpMeta?.alias || [],
    redirect: userLaKFke2gGpMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/pm/pages/pm/admin/collaboration/user.vue").then(m => m.default || m)
  },
  {
    name: indexrxgSXmkgFEMeta?.name ?? "pm-admin",
    path: indexrxgSXmkgFEMeta?.path ?? "/pm/admin",
    meta: indexrxgSXmkgFEMeta || {},
    alias: indexrxgSXmkgFEMeta?.alias || [],
    redirect: indexrxgSXmkgFEMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/pm/pages/pm/admin/index.vue").then(m => m.default || m)
  },
  {
    name: focusoDFsuWPV1uMeta?.name ?? "pm-admin-project-focus",
    path: focusoDFsuWPV1uMeta?.path ?? "/pm/admin/project/focus",
    meta: focusoDFsuWPV1uMeta || {},
    alias: focusoDFsuWPV1uMeta?.alias || [],
    redirect: focusoDFsuWPV1uMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/pm/pages/pm/admin/project/focus.vue").then(m => m.default || m)
  },
  {
    name: indexegFMVE4osyMeta?.name ?? "pm-admin-project",
    path: indexegFMVE4osyMeta?.path ?? "/pm/admin/project",
    meta: indexegFMVE4osyMeta || {},
    alias: indexegFMVE4osyMeta?.alias || [],
    redirect: indexegFMVE4osyMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/pm/pages/pm/admin/project/index.vue").then(m => m.default || m)
  },
  {
    name: index0y5xap34QBMeta?.name ?? "pm-admin-project-old",
    path: index0y5xap34QBMeta?.path ?? "/pm/admin/project/old",
    meta: index0y5xap34QBMeta || {},
    alias: index0y5xap34QBMeta?.alias || [],
    redirect: index0y5xap34QBMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/pm/pages/pm/admin/project/old/index.vue").then(m => m.default || m)
  },
  {
    name: star4wGGKMaNUOMeta?.name ?? "pm-admin-project-star",
    path: star4wGGKMaNUOMeta?.path ?? "/pm/admin/project/star",
    meta: star4wGGKMaNUOMeta || {},
    alias: star4wGGKMaNUOMeta?.alias || [],
    redirect: star4wGGKMaNUOMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/pm/pages/pm/admin/project/star.vue").then(m => m.default || m)
  },
  {
    name: groupnVzgnTE0wnMeta?.name ?? "pm-admin-setting-group",
    path: groupnVzgnTE0wnMeta?.path ?? "/pm/admin/setting/group",
    meta: groupnVzgnTE0wnMeta || {},
    alias: groupnVzgnTE0wnMeta?.alias || [],
    redirect: groupnVzgnTE0wnMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/pm/pages/pm/admin/setting/group.vue").then(m => m.default || m)
  },
  {
    name: userWVq9zJSBD5Meta?.name ?? "pm-admin-setting-user",
    path: userWVq9zJSBD5Meta?.path ?? "/pm/admin/setting/user",
    meta: userWVq9zJSBD5Meta || {},
    alias: userWVq9zJSBD5Meta?.alias || [],
    redirect: userWVq9zJSBD5Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/pm/pages/pm/admin/setting/user.vue").then(m => m.default || m)
  },
  {
    name: indexqCjhMmAP8pMeta?.name ?? "pm-admin-system",
    path: indexqCjhMmAP8pMeta?.path ?? "/pm/admin/system",
    meta: indexqCjhMmAP8pMeta || {},
    alias: indexqCjhMmAP8pMeta?.alias || [],
    redirect: indexqCjhMmAP8pMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/pm/pages/pm/admin/system/index.vue").then(m => m.default || m)
  },
  {
    name: indexd81J7Kn6kJMeta?.name ?? "pm",
    path: indexd81J7Kn6kJMeta?.path ?? "/pm",
    meta: indexd81J7Kn6kJMeta || {},
    alias: indexd81J7Kn6kJMeta?.alias || [],
    redirect: indexd81J7Kn6kJMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/pm/pages/pm/index.vue").then(m => m.default || m)
  },
  {
    name: clientT5tm6Jt0bdMeta?.name ?? "portal-doc-client",
    path: clientT5tm6Jt0bdMeta?.path ?? "/portal/doc/client",
    meta: clientT5tm6Jt0bdMeta || {},
    alias: clientT5tm6Jt0bdMeta?.alias || [],
    redirect: clientT5tm6Jt0bdMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/portal/pages/portal/doc/client.vue").then(m => m.default || m)
  },
  {
    name: servercWg0qc72XQMeta?.name ?? "portal-doc-server",
    path: servercWg0qc72XQMeta?.path ?? "/portal/doc/server",
    meta: servercWg0qc72XQMeta || {},
    alias: servercWg0qc72XQMeta?.alias || [],
    redirect: servercWg0qc72XQMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/portal/pages/portal/doc/server.vue").then(m => m.default || m)
  },
  {
    name: indexNtKg6puGK0Meta?.name ?? "portal-example-compose-id",
    path: indexNtKg6puGK0Meta?.path ?? "/portal/example/compose/:id()",
    meta: indexNtKg6puGK0Meta || {},
    alias: indexNtKg6puGK0Meta?.alias || [],
    redirect: indexNtKg6puGK0Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/portal/pages/portal/example/compose/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexsruerWNPKVMeta?.name ?? "portal-example-compose",
    path: indexsruerWNPKVMeta?.path ?? "/portal/example/compose",
    meta: indexsruerWNPKVMeta || {},
    alias: indexsruerWNPKVMeta?.alias || [],
    redirect: indexsruerWNPKVMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/portal/pages/portal/example/compose/index.vue").then(m => m.default || m)
  },
  {
    name: index9S8njmoVQsMeta?.name ?? "portal-example-exampleBrowsing-id",
    path: index9S8njmoVQsMeta?.path ?? "/portal/example/exampleBrowsing/:id()",
    meta: index9S8njmoVQsMeta || {},
    alias: index9S8njmoVQsMeta?.alias || [],
    redirect: index9S8njmoVQsMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/portal/pages/portal/example/exampleBrowsing/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexWM2uV2J5kaMeta?.name ?? "portal-example-exampleBrowsing",
    path: indexWM2uV2J5kaMeta?.path ?? "/portal/example/exampleBrowsing",
    meta: indexWM2uV2J5kaMeta || {},
    alias: indexWM2uV2J5kaMeta?.alias || [],
    redirect: indexWM2uV2J5kaMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/portal/pages/portal/example/exampleBrowsing/index.vue").then(m => m.default || m)
  },
  {
    name: groupQuzHJkMw08Meta?.name ?? "portal-example-group",
    path: groupQuzHJkMw08Meta?.path ?? "/portal/example/group",
    meta: groupQuzHJkMw08Meta || {},
    alias: groupQuzHJkMw08Meta?.alias || [],
    redirect: groupQuzHJkMw08Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/portal/pages/portal/example/group.vue").then(m => m.default || m)
  },
  {
    name: clientqdj44Xep87Meta?.name ?? "portal-home-docs-client",
    path: clientqdj44Xep87Meta?.path ?? "/portal/home/docs/client",
    meta: clientqdj44Xep87Meta || {},
    alias: clientqdj44Xep87Meta?.alias || [],
    redirect: clientqdj44Xep87Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/portal/pages/portal/home/docs/client.vue").then(m => m.default || m)
  },
  {
    name: serverK0fdQnBWq2Meta?.name ?? "portal-home-docs-server",
    path: serverK0fdQnBWq2Meta?.path ?? "/portal/home/docs/server",
    meta: serverK0fdQnBWq2Meta || {},
    alias: serverK0fdQnBWq2Meta?.alias || [],
    redirect: serverK0fdQnBWq2Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/portal/pages/portal/home/docs/server.vue").then(m => m.default || m)
  },
  {
    name: _91id_9373aQylDBVkMeta?.name ?? "portal-home-example-id",
    path: _91id_9373aQylDBVkMeta?.path ?? "/portal/home/example/:id()",
    meta: _91id_9373aQylDBVkMeta || {},
    alias: _91id_9373aQylDBVkMeta?.alias || [],
    redirect: _91id_9373aQylDBVkMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/portal/pages/portal/home/example/[id].vue").then(m => m.default || m)
  },
  {
    name: indexVYjwnYfCwhMeta?.name ?? "portal-home-example",
    path: indexVYjwnYfCwhMeta?.path ?? "/portal/home/example",
    meta: indexVYjwnYfCwhMeta || {},
    alias: indexVYjwnYfCwhMeta?.alias || [],
    redirect: indexVYjwnYfCwhMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/portal/pages/portal/home/example/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_930YmsDyPqsEMeta?.name ?? "portal-home-feature-id",
    path: _91id_930YmsDyPqsEMeta?.path ?? "/portal/home/feature/:id()",
    meta: _91id_930YmsDyPqsEMeta || {},
    alias: _91id_930YmsDyPqsEMeta?.alias || [],
    redirect: _91id_930YmsDyPqsEMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/portal/pages/portal/home/feature/[id].vue").then(m => m.default || m)
  },
  {
    name: indexWFVsy3wZFPMeta?.name ?? "portal-home-feature",
    path: indexWFVsy3wZFPMeta?.path ?? "/portal/home/feature",
    meta: indexWFVsy3wZFPMeta || {},
    alias: indexWFVsy3wZFPMeta?.alias || [],
    redirect: indexWFVsy3wZFPMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/portal/pages/portal/home/feature/index.vue").then(m => m.default || m)
  },
  {
    name: indexYQ48AXrNUaMeta?.name ?? "portal-home",
    path: indexYQ48AXrNUaMeta?.path ?? "/portal/home",
    meta: indexYQ48AXrNUaMeta || {},
    alias: indexYQ48AXrNUaMeta?.alias || [],
    redirect: indexYQ48AXrNUaMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/portal/pages/portal/home/index.vue").then(m => m.default || m)
  },
  {
    name: browseZM4nQDlKyDMeta?.name ?? "portal-home-standard-browse",
    path: browseZM4nQDlKyDMeta?.path ?? "/portal/home/standard/browse",
    meta: browseZM4nQDlKyDMeta || {},
    alias: browseZM4nQDlKyDMeta?.alias || [],
    redirect: browseZM4nQDlKyDMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/portal/pages/portal/home/standard/browse.vue").then(m => m.default || m)
  },
  {
    name: searchjLCmj2ZJWkMeta?.name ?? "portal-home-standard-search",
    path: searchjLCmj2ZJWkMeta?.path ?? "/portal/home/standard/search",
    meta: searchjLCmj2ZJWkMeta || {},
    alias: searchjLCmj2ZJWkMeta?.alias || [],
    redirect: searchjLCmj2ZJWkMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/portal/pages/portal/home/standard/search.vue").then(m => m.default || m)
  },
  {
    name: indexSAfUM2xDwmMeta?.name ?? "portal-scene-arrange-id",
    path: indexSAfUM2xDwmMeta?.path ?? "/portal/scene/arrange/:id()",
    meta: indexSAfUM2xDwmMeta || {},
    alias: indexSAfUM2xDwmMeta?.alias || [],
    redirect: indexSAfUM2xDwmMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/portal/pages/portal/scene/arrange/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexX44Nrz5waDMeta?.name ?? "portal-scene-arrange",
    path: indexX44Nrz5waDMeta?.path ?? "/portal/scene/arrange",
    meta: indexX44Nrz5waDMeta || {},
    alias: indexX44Nrz5waDMeta?.alias || [],
    redirect: indexX44Nrz5waDMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/portal/pages/portal/scene/arrange/index.vue").then(m => m.default || m)
  },
  {
    name: groupoDzqdCRnSjMeta?.name ?? "portal-scene-group",
    path: groupoDzqdCRnSjMeta?.path ?? "/portal/scene/group",
    meta: groupoDzqdCRnSjMeta || {},
    alias: groupoDzqdCRnSjMeta?.alias || [],
    redirect: groupoDzqdCRnSjMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/portal/pages/portal/scene/group.vue").then(m => m.default || m)
  },
  {
    name: indexB8pVCO5NpQMeta?.name ?? "portal-scene-sceneBrowsing-id",
    path: indexB8pVCO5NpQMeta?.path ?? "/portal/scene/sceneBrowsing/:id()",
    meta: indexB8pVCO5NpQMeta || {},
    alias: indexB8pVCO5NpQMeta?.alias || [],
    redirect: indexB8pVCO5NpQMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/portal/pages/portal/scene/sceneBrowsing/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: index2VyO8P8xmDMeta?.name ?? "portal-scene-sceneBrowsing",
    path: index2VyO8P8xmDMeta?.path ?? "/portal/scene/sceneBrowsing",
    meta: index2VyO8P8xmDMeta || {},
    alias: index2VyO8P8xmDMeta?.alias || [],
    redirect: index2VyO8P8xmDMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/portal/pages/portal/scene/sceneBrowsing/index.vue").then(m => m.default || m)
  },
  {
    name: prismaPzuoagO7lnMeta?.name ?? "prisma",
    path: prismaPzuoagO7lnMeta?.path ?? "/prisma",
    meta: prismaPzuoagO7lnMeta || {},
    alias: prismaPzuoagO7lnMeta?.alias || [],
    redirect: prismaPzuoagO7lnMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/framework/pages/prisma.vue").then(m => m.default || m)
  },
  {
    name: indexPnQ5eB8aUSMeta?.name ?? "project-org-access-member",
    path: indexPnQ5eB8aUSMeta?.path ?? "/project-org/access/member",
    meta: indexPnQ5eB8aUSMeta || {},
    alias: indexPnQ5eB8aUSMeta?.alias || [],
    redirect: indexPnQ5eB8aUSMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/project-org/pages/project-org/access/member/index.vue").then(m => m.default || m)
  },
  {
    name: indexiS6gd0AXmjMeta?.name ?? "project-org-access-policy",
    path: indexiS6gd0AXmjMeta?.path ?? "/project-org/access/policy",
    meta: indexiS6gd0AXmjMeta || {},
    alias: indexiS6gd0AXmjMeta?.alias || [],
    redirect: indexiS6gd0AXmjMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/project-org/pages/project-org/access/policy/index.vue").then(m => m.default || m)
  },
  {
    name: indexqh8l70U8RUMeta?.name ?? "project-org-access-role",
    path: indexqh8l70U8RUMeta?.path ?? "/project-org/access/role",
    meta: indexqh8l70U8RUMeta || {},
    alias: indexqh8l70U8RUMeta?.alias || [],
    redirect: indexqh8l70U8RUMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/project-org/pages/project-org/access/role/index.vue").then(m => m.default || m)
  },
  {
    name: indexNA72epHhJOMeta?.name ?? "project-org-culture-appearance",
    path: indexNA72epHhJOMeta?.path ?? "/project-org/culture/appearance",
    meta: indexNA72epHhJOMeta || {},
    alias: indexNA72epHhJOMeta?.alias || [],
    redirect: indexNA72epHhJOMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/project-org/pages/project-org/culture/appearance/index.vue").then(m => m.default || m)
  },
  {
    name: indexoWoaqKScxRMeta?.name ?? "project-org-culture-menu",
    path: indexoWoaqKScxRMeta?.path ?? "/project-org/culture/menu",
    meta: indexoWoaqKScxRMeta || {},
    alias: indexoWoaqKScxRMeta?.alias || [],
    redirect: indexoWoaqKScxRMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/project-org/pages/project-org/culture/menu/index.vue").then(m => m.default || m)
  },
  {
    name: indexyHenp6uXxvMeta?.name ?? "project-org",
    path: indexyHenp6uXxvMeta?.path ?? "/project-org",
    meta: indexyHenp6uXxvMeta || {},
    alias: indexyHenp6uXxvMeta?.alias || [],
    redirect: indexyHenp6uXxvMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/project-org/pages/project-org/index.vue").then(m => m.default || m)
  },
  {
    name: indexxSGPX2MBriMeta?.name ?? "project-org-integration-connect",
    path: indexxSGPX2MBriMeta?.path ?? "/project-org/integration/connect",
    meta: indexxSGPX2MBriMeta || {},
    alias: indexxSGPX2MBriMeta?.alias || [],
    redirect: indexxSGPX2MBriMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/project-org/pages/project-org/integration/connect/index.vue").then(m => m.default || m)
  },
  {
    name: indexsxj1OhVta2Meta?.name ?? "project-org-integration-iot",
    path: indexsxj1OhVta2Meta?.path ?? "/project-org/integration/iot",
    meta: indexsxj1OhVta2Meta || {},
    alias: indexsxj1OhVta2Meta?.alias || [],
    redirect: indexsxj1OhVta2Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/project-org/pages/project-org/integration/iot/index.vue").then(m => m.default || m)
  },
  {
    name: index6l8IWqj0AHMeta?.name ?? "project-project_oid-cehua-pbs-id_name",
    path: index6l8IWqj0AHMeta?.path ?? "/project/:project_oid()/cehua/pbs/:id_name()",
    meta: index6l8IWqj0AHMeta || {},
    alias: index6l8IWqj0AHMeta?.alias || [],
    redirect: index6l8IWqj0AHMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/project/pages/project/[project_oid]/cehua/pbs/[id_name]/index.vue").then(m => m.default || m)
  },
  {
    name: catalog_45selectlwOl55rBNMMeta?.name ?? "project-project_oid-cehua-pbs-catalog-select",
    path: catalog_45selectlwOl55rBNMMeta?.path ?? "/project/:project_oid()/cehua/pbs/catalog-select",
    meta: catalog_45selectlwOl55rBNMMeta || {},
    alias: catalog_45selectlwOl55rBNMMeta?.alias || [],
    redirect: catalog_45selectlwOl55rBNMMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/project/pages/project/[project_oid]/cehua/pbs/catalog-select.vue").then(m => m.default || m)
  },
  {
    name: catalog_45viewUhzPCQEUtZMeta?.name ?? "project-project_oid-cehua-pbs-catalog-view",
    path: catalog_45viewUhzPCQEUtZMeta?.path ?? "/project/:project_oid()/cehua/pbs/catalog-view",
    meta: catalog_45viewUhzPCQEUtZMeta || {},
    alias: catalog_45viewUhzPCQEUtZMeta?.alias || [],
    redirect: catalog_45viewUhzPCQEUtZMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/project/pages/project/[project_oid]/cehua/pbs/catalog-view.vue").then(m => m.default || m)
  },
  {
    name: index_46oldK6ivWaTsLhMeta?.name ?? "project-project_oid-cehua-pbs-index.old",
    path: index_46oldK6ivWaTsLhMeta?.path ?? "/project/:project_oid()/cehua/pbs/index.old",
    meta: index_46oldK6ivWaTsLhMeta || {},
    alias: index_46oldK6ivWaTsLhMeta?.alias || [],
    redirect: index_46oldK6ivWaTsLhMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/project/pages/project/[project_oid]/cehua/pbs/index.old.vue").then(m => m.default || m)
  },
  {
    name: indexK6VHOVmMPhMeta?.name ?? "project-project_oid-cehua-pbs",
    path: indexK6VHOVmMPhMeta?.path ?? "/project/:project_oid()/cehua/pbs",
    meta: indexK6VHOVmMPhMeta || {},
    alias: indexK6VHOVmMPhMeta?.alias || [],
    redirect: indexK6VHOVmMPhMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/project/pages/project/[project_oid]/cehua/pbs/index.vue").then(m => m.default || m)
  },
  {
    name: unittU6ktyc2kSMeta?.name ?? "project-project_oid-cehua-unit",
    path: unittU6ktyc2kSMeta?.path ?? "/project/:project_oid()/cehua/unit",
    meta: unittU6ktyc2kSMeta || {},
    alias: unittU6ktyc2kSMeta?.alias || [],
    redirect: unittU6ktyc2kSMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/project/pages/project/[project_oid]/cehua/unit.vue").then(m => m.default || m),
    children: [
  {
    name: normalK601V21X59Meta?.name ?? "project-project_oid-cehua-unit-normal",
    path: normalK601V21X59Meta?.path ?? "normal",
    meta: normalK601V21X59Meta || {},
    alias: normalK601V21X59Meta?.alias || [],
    redirect: normalK601V21X59Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/project/pages/project/[project_oid]/cehua/unit/normal.vue").then(m => m.default || m)
  },
  {
    name: trashXkRBKuMJKTMeta?.name ?? "project-project_oid-cehua-unit-trash",
    path: trashXkRBKuMJKTMeta?.path ?? "trash",
    meta: trashXkRBKuMJKTMeta || {},
    alias: trashXkRBKuMJKTMeta?.alias || [],
    redirect: trashXkRBKuMJKTMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/project/pages/project/[project_oid]/cehua/unit/trash.vue").then(m => m.default || m)
  }
]
  },
  {
    name: basicy30h0oK6mUMeta?.name ?? "project-project_oid-config-basic",
    path: basicy30h0oK6mUMeta?.path ?? "/project/:project_oid()/config/basic",
    meta: basicy30h0oK6mUMeta || {},
    alias: basicy30h0oK6mUMeta?.alias || [],
    redirect: basicy30h0oK6mUMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/project/pages/project/[project_oid]/config/basic.vue").then(m => m.default || m)
  },
  {
    name: indexUEw5PgNJiGMeta?.name ?? "project-project_oid",
    path: indexUEw5PgNJiGMeta?.path ?? "/project/:project_oid()",
    meta: indexUEw5PgNJiGMeta || {},
    alias: indexUEw5PgNJiGMeta?.alias || [],
    redirect: indexUEw5PgNJiGMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/project/pages/project/[project_oid]/index.vue").then(m => m.default || m)
  },
  {
    name: basic_45oldXxWZVG0eRtMeta?.name ?? "project-project_oid-info-basic-old",
    path: basic_45oldXxWZVG0eRtMeta?.path ?? "/project/:project_oid()/info/basic-old",
    meta: basic_45oldXxWZVG0eRtMeta || {},
    alias: basic_45oldXxWZVG0eRtMeta?.alias || [],
    redirect: basic_45oldXxWZVG0eRtMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/project/pages/project/[project_oid]/info/basic-old.vue").then(m => m.default || m)
  },
  {
    name: basicUwVLVRisIAMeta?.name ?? undefined,
    path: basicUwVLVRisIAMeta?.path ?? "/project/:project_oid()/info/basic",
    meta: basicUwVLVRisIAMeta || {},
    alias: basicUwVLVRisIAMeta?.alias || [],
    redirect: basicUwVLVRisIAMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/project/pages/project/[project_oid]/info/basic.vue").then(m => m.default || m),
    children: [
  {
    name: advanceSettingKRJgp2z4pAMeta?.name ?? "project-project_oid-info-basic-advanceSetting",
    path: advanceSettingKRJgp2z4pAMeta?.path ?? "advanceSetting",
    meta: advanceSettingKRJgp2z4pAMeta || {},
    alias: advanceSettingKRJgp2z4pAMeta?.alias || [],
    redirect: advanceSettingKRJgp2z4pAMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/project/pages/project/[project_oid]/info/basic/advanceSetting.vue").then(m => m.default || m)
  },
  {
    name: extendInfoORWnvXKM6mMeta?.name ?? "project-project_oid-info-basic-extendInfo",
    path: extendInfoORWnvXKM6mMeta?.path ?? "extendInfo",
    meta: extendInfoORWnvXKM6mMeta || {},
    alias: extendInfoORWnvXKM6mMeta?.alias || [],
    redirect: extendInfoORWnvXKM6mMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/project/pages/project/[project_oid]/info/basic/extendInfo.vue").then(m => m.default || m)
  },
  {
    name: indexx3WtM5HDPMMeta?.name ?? "project-project_oid-info-basic",
    path: indexx3WtM5HDPMMeta?.path ?? "",
    meta: indexx3WtM5HDPMMeta || {},
    alias: indexx3WtM5HDPMMeta?.alias || [],
    redirect: indexx3WtM5HDPMMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/project/pages/project/[project_oid]/info/basic/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: coverDjg5uG33nqMeta?.name ?? "project-project_oid-info-cover",
    path: coverDjg5uG33nqMeta?.path ?? "/project/:project_oid()/info/cover",
    meta: coverDjg5uG33nqMeta || {},
    alias: coverDjg5uG33nqMeta?.alias || [],
    redirect: coverDjg5uG33nqMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/project/pages/project/[project_oid]/info/cover.vue").then(m => m.default || m)
  },
  {
    name: map1yAexUZTypMeta?.name ?? "project-project_oid-info-map",
    path: map1yAexUZTypMeta?.path ?? "/project/:project_oid()/info/map",
    meta: map1yAexUZTypMeta || {},
    alias: map1yAexUZTypMeta?.alias || [],
    redirect: map1yAexUZTypMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/project/pages/project/[project_oid]/info/map.vue").then(m => m.default || m)
  },
  {
    name: protected9U4K1KWjdOMeta?.name ?? "protected",
    path: protected9U4K1KWjdOMeta?.path ?? "/protected",
    meta: protected9U4K1KWjdOMeta || {},
    alias: protected9U4K1KWjdOMeta?.alias || [],
    redirect: protected9U4K1KWjdOMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/framework/pages/protected.vue").then(m => m.default || m)
  },
  {
    name: _91id_93un8GjHNI1KMeta?.name ?? "render-first_catalog-id",
    path: _91id_93un8GjHNI1KMeta?.path ?? "/render/:first_catalog()/:id()",
    meta: _91id_93un8GjHNI1KMeta || {},
    alias: _91id_93un8GjHNI1KMeta?.alias || [],
    redirect: _91id_93un8GjHNI1KMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/render/pages/render/[first_catalog]/[id].vue").then(m => m.default || m)
  },
  {
    name: _91id_93zCYgbcNMYXMeta?.name ?? "render-first_catalog-second_catalog-id",
    path: _91id_93zCYgbcNMYXMeta?.path ?? "/render/:first_catalog()/:second_catalog()/:id()",
    meta: _91id_93zCYgbcNMYXMeta || {},
    alias: _91id_93zCYgbcNMYXMeta?.alias || [],
    redirect: _91id_93zCYgbcNMYXMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/render/pages/render/[first_catalog]/[second_catalog]/[id].vue").then(m => m.default || m)
  },
  {
    name: index8kLU5BrdY9Meta?.name ?? "render-first_catalog-second_catalog",
    path: index8kLU5BrdY9Meta?.path ?? "/render/:first_catalog()/:second_catalog()",
    meta: index8kLU5BrdY9Meta || {},
    alias: index8kLU5BrdY9Meta?.alias || [],
    redirect: index8kLU5BrdY9Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/render/pages/render/[first_catalog]/[second_catalog]/index.vue").then(m => m.default || m)
  },
  {
    name: indexRbZ44RZmX2Meta?.name ?? "render-first_catalog",
    path: indexRbZ44RZmX2Meta?.path ?? "/render/:first_catalog()",
    meta: indexRbZ44RZmX2Meta || {},
    alias: indexRbZ44RZmX2Meta?.alias || [],
    redirect: indexRbZ44RZmX2Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/render/pages/render/[first_catalog]/index.vue").then(m => m.default || m)
  },
  {
    name: indexM781rcq4iEMeta?.name ?? "render-doc",
    path: indexM781rcq4iEMeta?.path ?? "/render/doc",
    meta: indexM781rcq4iEMeta || {},
    alias: indexM781rcq4iEMeta?.alias || [],
    redirect: indexM781rcq4iEMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/render/pages/render/doc/index.vue").then(m => m.default || m)
  },
  {
    name: editPLWCnYkTYGMeta?.name ?? "render-example_old-first_catalog-id-edit",
    path: editPLWCnYkTYGMeta?.path ?? "/render/example_old/:first_catalog()/:id()/edit",
    meta: editPLWCnYkTYGMeta || {},
    alias: editPLWCnYkTYGMeta?.alias || [],
    redirect: editPLWCnYkTYGMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/render/pages/render/example_old/[first_catalog]/[id]/edit.vue").then(m => m.default || m)
  },
  {
    name: indexM68MnxYDKVMeta?.name ?? "render-example_old-first_catalog-id",
    path: indexM68MnxYDKVMeta?.path ?? "/render/example_old/:first_catalog()/:id()",
    meta: indexM68MnxYDKVMeta || {},
    alias: indexM68MnxYDKVMeta?.alias || [],
    redirect: indexM68MnxYDKVMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/render/pages/render/example_old/[first_catalog]/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexvl7kZWYRntMeta?.name ?? "render-example_old-first_catalog",
    path: indexvl7kZWYRntMeta?.path ?? "/render/example_old/:first_catalog()",
    meta: indexvl7kZWYRntMeta || {},
    alias: indexvl7kZWYRntMeta?.alias || [],
    redirect: indexvl7kZWYRntMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/render/pages/render/example_old/[first_catalog]/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93U2JC7MnCBFMeta?.name ?? "render-example-scene-id",
    path: _91id_93U2JC7MnCBFMeta?.path ?? "/render/example/scene/:id()",
    meta: _91id_93U2JC7MnCBFMeta || {},
    alias: _91id_93U2JC7MnCBFMeta?.alias || [],
    redirect: _91id_93U2JC7MnCBFMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/render/pages/render/example/scene/[id].vue").then(m => m.default || m)
  },
  {
    name: indexAPIwYlk48WMeta?.name ?? "render-example-scene",
    path: indexAPIwYlk48WMeta?.path ?? "/render/example/scene",
    meta: indexAPIwYlk48WMeta || {},
    alias: indexAPIwYlk48WMeta?.alias || [],
    redirect: indexAPIwYlk48WMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/render/pages/render/example/scene/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93BacTZ4Q6G4Meta?.name ?? "render-gallery-id",
    path: _91id_93BacTZ4Q6G4Meta?.path ?? "/render/gallery/:id()",
    meta: _91id_93BacTZ4Q6G4Meta || {},
    alias: _91id_93BacTZ4Q6G4Meta?.alias || [],
    redirect: _91id_93BacTZ4Q6G4Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/render/pages/render/gallery/[id].vue").then(m => m.default || m)
  },
  {
    name: indexonXuJQa20AMeta?.name ?? "render-gallery",
    path: indexonXuJQa20AMeta?.path ?? "/render/gallery",
    meta: indexonXuJQa20AMeta || {},
    alias: indexonXuJQa20AMeta?.alias || [],
    redirect: indexonXuJQa20AMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/render/pages/render/gallery/index.vue").then(m => m.default || m)
  },
  {
    name: indexWNra5jtU9UMeta?.name ?? "render-scaffold",
    path: indexWNra5jtU9UMeta?.path ?? "/render/scaffold",
    meta: indexWNra5jtU9UMeta || {},
    alias: indexWNra5jtU9UMeta?.alias || [],
    redirect: indexWNra5jtU9UMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/render/pages/render/scaffold/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_932eSx6UX8CEMeta?.name ?? "render-scenedisplay-id",
    path: _91id_932eSx6UX8CEMeta?.path ?? "/render/scenedisplay/:id()",
    meta: _91id_932eSx6UX8CEMeta || {},
    alias: _91id_932eSx6UX8CEMeta?.alias || [],
    redirect: _91id_932eSx6UX8CEMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/render/pages/render/scenedisplay/[id].vue").then(m => m.default || m)
  },
  {
    name: indexKTysMnEKnwMeta?.name ?? "render-scenedisplay",
    path: indexKTysMnEKnwMeta?.path ?? "/render/scenedisplay",
    meta: indexKTysMnEKnwMeta || {},
    alias: indexKTysMnEKnwMeta?.alias || [],
    redirect: indexKTysMnEKnwMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/render/pages/render/scenedisplay/index.vue").then(m => m.default || m)
  },
  {
    name: doc_46oldMlIo2JwYCAMeta?.name ?? "render-sdk-doc.old",
    path: doc_46oldMlIo2JwYCAMeta?.path ?? "/render/sdk/doc.old",
    meta: doc_46oldMlIo2JwYCAMeta || {},
    alias: doc_46oldMlIo2JwYCAMeta?.alias || [],
    redirect: doc_46oldMlIo2JwYCAMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/render/pages/render/sdk/doc.old.vue").then(m => m.default || m)
  },
  {
    name: docPKOJ8tnZKGMeta?.name ?? "render-sdk-doc",
    path: docPKOJ8tnZKGMeta?.path ?? "/render/sdk/doc",
    meta: docPKOJ8tnZKGMeta || {},
    alias: docPKOJ8tnZKGMeta?.alias || [],
    redirect: docPKOJ8tnZKGMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/render/pages/render/sdk/doc.vue").then(m => m.default || m)
  },
  {
    name: doc_46oldJEEWmRYWGGMeta?.name ?? "render-server_sdk-doc.old",
    path: doc_46oldJEEWmRYWGGMeta?.path ?? "/render/server_sdk/doc.old",
    meta: doc_46oldJEEWmRYWGGMeta || {},
    alias: doc_46oldJEEWmRYWGGMeta?.alias || [],
    redirect: doc_46oldJEEWmRYWGGMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/render/pages/render/server_sdk/doc.old.vue").then(m => m.default || m)
  },
  {
    name: docRxbBZnouJuMeta?.name ?? "render-server_sdk-doc",
    path: docRxbBZnouJuMeta?.path ?? "/render/server_sdk/doc",
    meta: docRxbBZnouJuMeta || {},
    alias: docRxbBZnouJuMeta?.alias || [],
    redirect: docRxbBZnouJuMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/render/pages/render/server_sdk/doc.vue").then(m => m.default || m)
  },
  {
    name: indexLHXBwqhfzjMeta?.name ?? "schedule-project_id-analyse-critical",
    path: indexLHXBwqhfzjMeta?.path ?? "/schedule/:project_id()/analyse/critical",
    meta: indexLHXBwqhfzjMeta || {},
    alias: indexLHXBwqhfzjMeta?.alias || [],
    redirect: indexLHXBwqhfzjMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/analyse/critical/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93OuHkS650sSMeta?.name ?? "schedule-project_id-analyse-deviation-id",
    path: _91id_93OuHkS650sSMeta?.path ?? "/schedule/:project_id()/analyse/deviation/:id()",
    meta: _91id_93OuHkS650sSMeta || {},
    alias: _91id_93OuHkS650sSMeta?.alias || [],
    redirect: _91id_93OuHkS650sSMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/analyse/deviation/[id].vue").then(m => m.default || m),
    children: [
  {
    name: indexyOHr35jXH1Meta?.name ?? "schedule-project_id-analyse-deviation-id-begin-work",
    path: indexyOHr35jXH1Meta?.path ?? "begin-work",
    meta: indexyOHr35jXH1Meta || {},
    alias: indexyOHr35jXH1Meta?.alias || [],
    redirect: indexyOHr35jXH1Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/analyse/deviation/[id]/begin-work/index.vue").then(m => m.default || m)
  },
  {
    name: indexkqBz96fk7VMeta?.name ?? "schedule-project_id-analyse-deviation-id-bim-model",
    path: indexkqBz96fk7VMeta?.path ?? "bim-model",
    meta: indexkqBz96fk7VMeta || {},
    alias: indexkqBz96fk7VMeta?.alias || [],
    redirect: indexkqBz96fk7VMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/analyse/deviation/[id]/bim-model/index.vue").then(m => m.default || m)
  },
  {
    name: indexHCLSUvFAMTMeta?.name ?? "schedule-project_id-analyse-deviation-id-duration",
    path: indexHCLSUvFAMTMeta?.path ?? "duration",
    meta: indexHCLSUvFAMTMeta || {},
    alias: indexHCLSUvFAMTMeta?.alias || [],
    redirect: indexHCLSUvFAMTMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/analyse/deviation/[id]/duration/index.vue").then(m => m.default || m)
  },
  {
    name: indexYsGO6FcdJGMeta?.name ?? "schedule-project_id-analyse-deviation-id-end-work",
    path: indexYsGO6FcdJGMeta?.path ?? "end-work",
    meta: indexYsGO6FcdJGMeta || {},
    alias: indexYsGO6FcdJGMeta?.alias || [],
    redirect: indexYsGO6FcdJGMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/analyse/deviation/[id]/end-work/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexJeYjVR3lA1Meta?.name ?? "schedule-project_id-analyse-deviation",
    path: indexJeYjVR3lA1Meta?.path ?? "/schedule/:project_id()/analyse/deviation",
    meta: indexJeYjVR3lA1Meta || {},
    alias: indexJeYjVR3lA1Meta?.alias || [],
    redirect: indexJeYjVR3lA1Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/analyse/deviation/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93FonjLOr6iYMeta?.name ?? "schedule-project_id-analyse-simulate-id",
    path: _91id_93FonjLOr6iYMeta?.path ?? "/schedule/:project_id()/analyse/simulate/:id()",
    meta: _91id_93FonjLOr6iYMeta || {},
    alias: _91id_93FonjLOr6iYMeta?.alias || [],
    redirect: _91id_93FonjLOr6iYMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/analyse/simulate/[id].vue").then(m => m.default || m),
    children: [
  {
    name: indexgiA1wDlMJXMeta?.name ?? "schedule-project_id-analyse-simulate-id-actual",
    path: indexgiA1wDlMJXMeta?.path ?? "actual",
    meta: indexgiA1wDlMJXMeta || {},
    alias: indexgiA1wDlMJXMeta?.alias || [],
    redirect: indexgiA1wDlMJXMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/analyse/simulate/[id]/actual/index.vue").then(m => m.default || m)
  },
  {
    name: indexjOkvNKPsTzMeta?.name ?? "schedule-project_id-analyse-simulate-id-plan",
    path: indexjOkvNKPsTzMeta?.path ?? "plan",
    meta: indexjOkvNKPsTzMeta || {},
    alias: indexjOkvNKPsTzMeta?.alias || [],
    redirect: indexjOkvNKPsTzMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/analyse/simulate/[id]/plan/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexoNvemXQAPCMeta?.name ?? "schedule-project_id-analyse-simulate",
    path: indexoNvemXQAPCMeta?.path ?? "/schedule/:project_id()/analyse/simulate",
    meta: indexoNvemXQAPCMeta || {},
    alias: indexoNvemXQAPCMeta?.alias || [],
    redirect: indexoNvemXQAPCMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/analyse/simulate/index.vue").then(m => m.default || m)
  },
  {
    name: indexZhO2OeVHlBMeta?.name ?? "schedule-project_id-optimize-cost",
    path: indexZhO2OeVHlBMeta?.path ?? "/schedule/:project_id()/optimize/cost",
    meta: indexZhO2OeVHlBMeta || {},
    alias: indexZhO2OeVHlBMeta?.alias || [],
    redirect: indexZhO2OeVHlBMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/optimize/cost/index.vue").then(m => m.default || m)
  },
  {
    name: tabsQVcADg1YW7Meta?.name ?? "schedule-project_id-optimize-cost-tabs",
    path: tabsQVcADg1YW7Meta?.path ?? "/schedule/:project_id()/optimize/cost/tabs",
    meta: tabsQVcADg1YW7Meta || {},
    alias: tabsQVcADg1YW7Meta?.alias || [],
    redirect: tabsQVcADg1YW7Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/optimize/cost/tabs.vue").then(m => m.default || m),
    children: [
  {
    name: indexqv0LADtdpLMeta?.name ?? "schedule-project_id-optimize-cost-tabs-analysis",
    path: indexqv0LADtdpLMeta?.path ?? "analysis",
    meta: indexqv0LADtdpLMeta || {},
    alias: indexqv0LADtdpLMeta?.alias || [],
    redirect: indexqv0LADtdpLMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/optimize/cost/tabs/analysis/index.vue").then(m => m.default || m)
  },
  {
    name: indexStGopMq1I7Meta?.name ?? "schedule-project_id-optimize-cost-tabs-deviation",
    path: indexStGopMq1I7Meta?.path ?? "deviation",
    meta: indexStGopMq1I7Meta || {},
    alias: indexStGopMq1I7Meta?.alias || [],
    redirect: indexStGopMq1I7Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/optimize/cost/tabs/deviation/index.vue").then(m => m.default || m)
  },
  {
    name: indexcqqvX3wL1tMeta?.name ?? "schedule-project_id-optimize-cost-tabs-information",
    path: indexcqqvX3wL1tMeta?.path ?? "information",
    meta: indexcqqvX3wL1tMeta || {},
    alias: indexcqqvX3wL1tMeta?.alias || [],
    redirect: indexcqqvX3wL1tMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/optimize/cost/tabs/information/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_93HD1aRz2o4tMeta?.name ?? "schedule-project_id-optimize-renew-id",
    path: _91id_93HD1aRz2o4tMeta?.path ?? "/schedule/:project_id()/optimize/renew/:id()",
    meta: _91id_93HD1aRz2o4tMeta || {},
    alias: _91id_93HD1aRz2o4tMeta?.alias || [],
    redirect: _91id_93HD1aRz2o4tMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/optimize/renew/[id].vue").then(m => m.default || m),
    children: [
  {
    name: indexHPKwfpNDYRMeta?.name ?? "schedule-project_id-optimize-renew-id-now",
    path: indexHPKwfpNDYRMeta?.path ?? "now",
    meta: indexHPKwfpNDYRMeta || {},
    alias: indexHPKwfpNDYRMeta?.alias || [],
    redirect: indexHPKwfpNDYRMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/optimize/renew/[id]/now/index.vue").then(m => m.default || m)
  },
  {
    name: indexFGC7R0FHzJMeta?.name ?? "schedule-project_id-optimize-renew-id-plan",
    path: indexFGC7R0FHzJMeta?.path ?? "plan",
    meta: indexFGC7R0FHzJMeta || {},
    alias: indexFGC7R0FHzJMeta?.alias || [],
    redirect: indexFGC7R0FHzJMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/optimize/renew/[id]/plan/index.vue").then(m => m.default || m)
  },
  {
    name: useSvgO8Cky92tHUMeta?.name ?? "schedule-project_id-optimize-renew-id-useSvg",
    path: useSvgO8Cky92tHUMeta?.path ?? "useSvg",
    meta: useSvgO8Cky92tHUMeta || {},
    alias: useSvgO8Cky92tHUMeta?.alias || [],
    redirect: useSvgO8Cky92tHUMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/optimize/renew/[id]/useSvg.ts").then(m => m.default || m)
  }
]
  },
  {
    name: indexxe8TAdv9Y8Meta?.name ?? "schedule-project_id-optimize-renew",
    path: indexxe8TAdv9Y8Meta?.path ?? "/schedule/:project_id()/optimize/renew",
    meta: indexxe8TAdv9Y8Meta || {},
    alias: indexxe8TAdv9Y8Meta?.alias || [],
    redirect: indexxe8TAdv9Y8Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/optimize/renew/index.vue").then(m => m.default || m)
  },
  {
    name: indexmx6LuqmznlMeta?.name ?? "schedule-project_id-overview",
    path: indexmx6LuqmznlMeta?.path ?? "/schedule/:project_id()/overview",
    meta: indexmx6LuqmznlMeta || {},
    alias: indexmx6LuqmznlMeta?.alias || [],
    redirect: indexmx6LuqmznlMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/overview/index.vue").then(m => m.default || m)
  },
  {
    name: indexUm0Da5oRHzMeta?.name ?? "schedule-project_id-plan",
    path: indexUm0Da5oRHzMeta?.path ?? "/schedule/:project_id()/plan",
    meta: indexUm0Da5oRHzMeta || {},
    alias: indexUm0Da5oRHzMeta?.alias || [],
    redirect: indexUm0Da5oRHzMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/plan/index.vue").then(m => m.default || m)
  },
  {
    name: indexA9vXXYtf6lMeta?.name ?? "schedule-project_id-plan-main",
    path: indexA9vXXYtf6lMeta?.path ?? "/schedule/:project_id()/plan/main",
    meta: indexA9vXXYtf6lMeta || {},
    alias: indexA9vXXYtf6lMeta?.alias || [],
    redirect: indexA9vXXYtf6lMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/plan/main/index.vue").then(m => m.default || m)
  },
  {
    name: indexJnYjWQ4vRDMeta?.name ?? "schedule-project_id-plan-major",
    path: indexJnYjWQ4vRDMeta?.path ?? "/schedule/:project_id()/plan/major",
    meta: indexJnYjWQ4vRDMeta || {},
    alias: indexJnYjWQ4vRDMeta?.alias || [],
    redirect: indexJnYjWQ4vRDMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/plan/major/index.vue").then(m => m.default || m)
  },
  {
    name: tabsxKyln0GPAjMeta?.name ?? "schedule-project_id-plan-major-tabs",
    path: tabsxKyln0GPAjMeta?.path ?? "/schedule/:project_id()/plan/major/tabs",
    meta: tabsxKyln0GPAjMeta || {},
    alias: tabsxKyln0GPAjMeta?.alias || [],
    redirect: tabsxKyln0GPAjMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/plan/major/tabs.vue").then(m => m.default || m),
    children: [
  {
    name: index56WSNxMjMxMeta?.name ?? "schedule-project_id-plan-major-tabs-gantt-chart",
    path: index56WSNxMjMxMeta?.path ?? "gantt-chart",
    meta: index56WSNxMjMxMeta || {},
    alias: index56WSNxMjMxMeta?.alias || [],
    redirect: index56WSNxMjMxMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/plan/major/tabs/gantt-chart/index.vue").then(m => m.default || m)
  },
  {
    name: indexKnWwmpatwZMeta?.name ?? "schedule-project_id-plan-major-tabs-network-diagram",
    path: indexKnWwmpatwZMeta?.path ?? "network-diagram",
    meta: indexKnWwmpatwZMeta || {},
    alias: indexKnWwmpatwZMeta?.alias || [],
    redirect: indexKnWwmpatwZMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/plan/major/tabs/network-diagram/index.vue").then(m => m.default || m)
  },
  {
    name: useSvgWzCqyHWwA2Meta?.name ?? "schedule-project_id-plan-major-tabs-network-diagram-useSvg",
    path: useSvgWzCqyHWwA2Meta?.path ?? "network-diagram/useSvg",
    meta: useSvgWzCqyHWwA2Meta || {},
    alias: useSvgWzCqyHWwA2Meta?.alias || [],
    redirect: useSvgWzCqyHWwA2Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/plan/major/tabs/network-diagram/useSvg.ts").then(m => m.default || m)
  },
  {
    name: indexCpMuMv1vYaMeta?.name ?? "schedule-project_id-plan-major-tabs-worksheet",
    path: indexCpMuMv1vYaMeta?.path ?? "worksheet",
    meta: indexCpMuMv1vYaMeta || {},
    alias: indexCpMuMv1vYaMeta?.alias || [],
    redirect: indexCpMuMv1vYaMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/plan/major/tabs/worksheet/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: _91id_93l3WHTcn4opMeta?.name ?? "schedule-project_id-plan-sub-id",
    path: _91id_93l3WHTcn4opMeta?.path ?? "/schedule/:project_id()/plan/sub/:id()",
    meta: _91id_93l3WHTcn4opMeta || {},
    alias: _91id_93l3WHTcn4opMeta?.alias || [],
    redirect: _91id_93l3WHTcn4opMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/plan/sub/[id].vue").then(m => m.default || m),
    children: [
  {
    name: index8niGiif2dCMeta?.name ?? "schedule-project_id-plan-sub-id-bim-model",
    path: index8niGiif2dCMeta?.path ?? "bim-model",
    meta: index8niGiif2dCMeta || {},
    alias: index8niGiif2dCMeta?.alias || [],
    redirect: index8niGiif2dCMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/plan/sub/[id]/bim-model/index.vue").then(m => m.default || m)
  },
  {
    name: indexHfKAmViDQnMeta?.name ?? "schedule-project_id-plan-sub-id-gantt-chart",
    path: indexHfKAmViDQnMeta?.path ?? "gantt-chart",
    meta: indexHfKAmViDQnMeta || {},
    alias: indexHfKAmViDQnMeta?.alias || [],
    redirect: indexHfKAmViDQnMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/plan/sub/[id]/gantt-chart/index.vue").then(m => m.default || m)
  },
  {
    name: indexOCb0QdL8bnMeta?.name ?? "schedule-project_id-plan-sub-id-network-diagram",
    path: indexOCb0QdL8bnMeta?.path ?? "network-diagram",
    meta: indexOCb0QdL8bnMeta || {},
    alias: indexOCb0QdL8bnMeta?.alias || [],
    redirect: indexOCb0QdL8bnMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/plan/sub/[id]/network-diagram/index.vue").then(m => m.default || m)
  },
  {
    name: useSvgBqzhVnoATjMeta?.name ?? "schedule-project_id-plan-sub-id-network-diagram-useSvg",
    path: useSvgBqzhVnoATjMeta?.path ?? "network-diagram/useSvg",
    meta: useSvgBqzhVnoATjMeta || {},
    alias: useSvgBqzhVnoATjMeta?.alias || [],
    redirect: useSvgBqzhVnoATjMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/plan/sub/[id]/network-diagram/useSvg.ts").then(m => m.default || m)
  },
  {
    name: key_45worksm241dV4cMcMeta?.name ?? "schedule-project_id-plan-sub-id-overview-components-key-works",
    path: key_45worksm241dV4cMcMeta?.path ?? "overview/components/key-works",
    meta: key_45worksm241dV4cMcMeta || {},
    alias: key_45worksm241dV4cMcMeta?.alias || [],
    redirect: key_45worksm241dV4cMcMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/plan/sub/[id]/overview/components/key-works.vue").then(m => m.default || m)
  },
  {
    name: indexZGmcvqpnoOMeta?.name ?? "schedule-project_id-plan-sub-id-overview",
    path: indexZGmcvqpnoOMeta?.path ?? "overview",
    meta: indexZGmcvqpnoOMeta || {},
    alias: indexZGmcvqpnoOMeta?.alias || [],
    redirect: indexZGmcvqpnoOMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/plan/sub/[id]/overview/index.vue").then(m => m.default || m)
  },
  {
    name: indexGTZ4JlPZlbMeta?.name ?? "schedule-project_id-plan-sub-id-worksheet",
    path: indexGTZ4JlPZlbMeta?.path ?? "worksheet",
    meta: indexGTZ4JlPZlbMeta || {},
    alias: indexGTZ4JlPZlbMeta?.alias || [],
    redirect: indexGTZ4JlPZlbMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/plan/sub/[id]/worksheet/index.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexsSAshZeOqPMeta?.name ?? "schedule-project_id-plan-sub",
    path: indexsSAshZeOqPMeta?.path ?? "/schedule/:project_id()/plan/sub",
    meta: indexsSAshZeOqPMeta || {},
    alias: indexsSAshZeOqPMeta?.alias || [],
    redirect: indexsSAshZeOqPMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/plan/sub/index.vue").then(m => m.default || m)
  },
  {
    name: indexoRaENkzsLVMeta?.name ?? "schedule-project_id-tracking-duration-id",
    path: indexoRaENkzsLVMeta?.path ?? "/schedule/:project_id()/tracking/duration/:id()",
    meta: indexoRaENkzsLVMeta || {},
    alias: indexoRaENkzsLVMeta?.alias || [],
    redirect: indexoRaENkzsLVMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/tracking/duration/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexkBjf2vZ4msMeta?.name ?? "schedule-project_id-tracking-duration",
    path: indexkBjf2vZ4msMeta?.path ?? "/schedule/:project_id()/tracking/duration",
    meta: indexkBjf2vZ4msMeta || {},
    alias: indexkBjf2vZ4msMeta?.alias || [],
    redirect: indexkBjf2vZ4msMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/tracking/duration/index.vue").then(m => m.default || m)
  },
  {
    name: indexmzi6vGKI17Meta?.name ?? "schedule-project_id-tracking-entity-id",
    path: indexmzi6vGKI17Meta?.path ?? "/schedule/:project_id()/tracking/entity/:id()",
    meta: indexmzi6vGKI17Meta || {},
    alias: indexmzi6vGKI17Meta?.alias || [],
    redirect: indexmzi6vGKI17Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/tracking/entity/[id]/index.vue").then(m => m.default || m)
  },
  {
    name: indexpKbsaiVj6xMeta?.name ?? "schedule-project_id-tracking-entity",
    path: indexpKbsaiVj6xMeta?.path ?? "/schedule/:project_id()/tracking/entity",
    meta: indexpKbsaiVj6xMeta || {},
    alias: indexpKbsaiVj6xMeta?.alias || [],
    redirect: indexpKbsaiVj6xMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/tracking/entity/index.vue").then(m => m.default || m)
  },
  {
    name: indexgSn8Mh1TnGMeta?.name ?? "schedule-project_id-tracking-figure",
    path: indexgSn8Mh1TnGMeta?.path ?? "/schedule/:project_id()/tracking/figure",
    meta: indexgSn8Mh1TnGMeta || {},
    alias: indexgSn8Mh1TnGMeta?.alias || [],
    redirect: indexgSn8Mh1TnGMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/[project_id]/tracking/figure/index.vue").then(m => m.default || m)
  },
  {
    name: indexL7ieeUyD70Meta?.name ?? "schedule",
    path: indexL7ieeUyD70Meta?.path ?? "/schedule",
    meta: indexL7ieeUyD70Meta || {},
    alias: indexL7ieeUyD70Meta?.alias || [],
    redirect: indexL7ieeUyD70Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93dpc48tutEvMeta?.name ?? "schedule-todos-id",
    path: _91id_93dpc48tutEvMeta?.path ?? "/schedule/todos/:id()",
    meta: _91id_93dpc48tutEvMeta || {},
    alias: _91id_93dpc48tutEvMeta?.alias || [],
    redirect: _91id_93dpc48tutEvMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/todos/[id].vue").then(m => m.default || m)
  },
  {
    name: indexKSJtXfYDdmMeta?.name ?? "schedule-todos",
    path: indexKSJtXfYDdmMeta?.path ?? "/schedule/todos",
    meta: indexKSJtXfYDdmMeta || {},
    alias: indexKSJtXfYDdmMeta?.alias || [],
    redirect: indexKSJtXfYDdmMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/schedule/pages/schedule/todos/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93VbrnRYDcQDMeta?.name ?? "standard-delivery-id",
    path: _91id_93VbrnRYDcQDMeta?.path ?? "/standard/delivery/:id()",
    meta: _91id_93VbrnRYDcQDMeta || {},
    alias: _91id_93VbrnRYDcQDMeta?.alias || [],
    redirect: _91id_93VbrnRYDcQDMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/standard/pages/standard/delivery/[id].vue").then(m => m.default || m),
    children: [
  {
    name: entitycheckzN5CucxtHYMeta?.name ?? "standard-delivery-id-entitycheck",
    path: entitycheckzN5CucxtHYMeta?.path ?? "entitycheck",
    meta: entitycheckzN5CucxtHYMeta || {},
    alias: entitycheckzN5CucxtHYMeta?.alias || [],
    redirect: entitycheckzN5CucxtHYMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/standard/pages/standard/delivery/[id]/entitycheck.vue").then(m => m.default || m)
  },
  {
    name: geometrycheckdAvwsn420bMeta?.name ?? "standard-delivery-id-geometrycheck",
    path: geometrycheckdAvwsn420bMeta?.path ?? "geometrycheck",
    meta: geometrycheckdAvwsn420bMeta || {},
    alias: geometrycheckdAvwsn420bMeta?.alias || [],
    redirect: geometrycheckdAvwsn420bMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/standard/pages/standard/delivery/[id]/geometrycheck.vue").then(m => m.default || m)
  },
  {
    name: materialchecktBLcO4J35pMeta?.name ?? "standard-delivery-id-materialcheck",
    path: materialchecktBLcO4J35pMeta?.path ?? "materialcheck",
    meta: materialchecktBLcO4J35pMeta || {},
    alias: materialchecktBLcO4J35pMeta?.alias || [],
    redirect: materialchecktBLcO4J35pMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/standard/pages/standard/delivery/[id]/materialcheck.vue").then(m => m.default || m)
  },
  {
    name: propertycheckefHo2dnNfrMeta?.name ?? "standard-delivery-id-propertycheck",
    path: propertycheckefHo2dnNfrMeta?.path ?? "propertycheck",
    meta: propertycheckefHo2dnNfrMeta || {},
    alias: propertycheckefHo2dnNfrMeta?.alias || [],
    redirect: propertycheckefHo2dnNfrMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/standard/pages/standard/delivery/[id]/propertycheck.vue").then(m => m.default || m)
  },
  {
    name: quantitycheckTm48cV7Th0Meta?.name ?? "standard-delivery-id-quantitycheck",
    path: quantitycheckTm48cV7Th0Meta?.path ?? "quantitycheck",
    meta: quantitycheckTm48cV7Th0Meta || {},
    alias: quantitycheckTm48cV7Th0Meta?.alias || [],
    redirect: quantitycheckTm48cV7Th0Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/standard/pages/standard/delivery/[id]/quantitycheck.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexuQ89XuwAkxMeta?.name ?? "standard-delivery",
    path: indexuQ89XuwAkxMeta?.path ?? "/standard/delivery",
    meta: indexuQ89XuwAkxMeta || {},
    alias: indexuQ89XuwAkxMeta?.alias || [],
    redirect: indexuQ89XuwAkxMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/standard/pages/standard/delivery/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_938abran2sYZMeta?.name ?? "standard-dictionary-id",
    path: _91id_938abran2sYZMeta?.path ?? "/standard/dictionary/:id()",
    meta: _91id_938abran2sYZMeta || {},
    alias: _91id_938abran2sYZMeta?.alias || [],
    redirect: _91id_938abran2sYZMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/standard/pages/standard/dictionary/[id].vue").then(m => m.default || m)
  },
  {
    name: indexzXbBUXlEx4Meta?.name ?? "standard-dictionary",
    path: indexzXbBUXlEx4Meta?.path ?? "/standard/dictionary",
    meta: indexzXbBUXlEx4Meta || {},
    alias: indexzXbBUXlEx4Meta?.alias || [],
    redirect: indexzXbBUXlEx4Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/standard/pages/standard/dictionary/index.vue").then(m => m.default || m)
  },
  {
    name: _91id_93z6m0OTZANaMeta?.name ?? "standard-schema-id",
    path: _91id_93z6m0OTZANaMeta?.path ?? "/standard/schema/:id()",
    meta: _91id_93z6m0OTZANaMeta || {},
    alias: _91id_93z6m0OTZANaMeta?.alias || [],
    redirect: _91id_93z6m0OTZANaMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/standard/pages/standard/schema/[id].vue").then(m => m.default || m),
    children: [
  {
    name: entity2dwdSiMrQ2Meta?.name ?? "standard-schema-id-entity",
    path: entity2dwdSiMrQ2Meta?.path ?? "entity",
    meta: entity2dwdSiMrQ2Meta || {},
    alias: entity2dwdSiMrQ2Meta?.alias || [],
    redirect: entity2dwdSiMrQ2Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/standard/pages/standard/schema/[id]/entity.vue").then(m => m.default || m)
  },
  {
    name: enumNQZF0TsdnbMeta?.name ?? "standard-schema-id-enum",
    path: enumNQZF0TsdnbMeta?.path ?? "enum",
    meta: enumNQZF0TsdnbMeta || {},
    alias: enumNQZF0TsdnbMeta?.alias || [],
    redirect: enumNQZF0TsdnbMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/standard/pages/standard/schema/[id]/enum.vue").then(m => m.default || m)
  },
  {
    name: psetewNo1CGxopMeta?.name ?? "standard-schema-id-pset",
    path: psetewNo1CGxopMeta?.path ?? "pset",
    meta: psetewNo1CGxopMeta || {},
    alias: psetewNo1CGxopMeta?.alias || [],
    redirect: psetewNo1CGxopMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/standard/pages/standard/schema/[id]/pset.vue").then(m => m.default || m)
  },
  {
    name: qsetRFTEPktbTqMeta?.name ?? "standard-schema-id-qset",
    path: qsetRFTEPktbTqMeta?.path ?? "qset",
    meta: qsetRFTEPktbTqMeta || {},
    alias: qsetRFTEPktbTqMeta?.alias || [],
    redirect: qsetRFTEPktbTqMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/standard/pages/standard/schema/[id]/qset.vue").then(m => m.default || m)
  }
]
  },
  {
    name: indexts6XMVWmIaMeta?.name ?? "standard-schema",
    path: indexts6XMVWmIaMeta?.path ?? "/standard/schema",
    meta: indexts6XMVWmIaMeta || {},
    alias: indexts6XMVWmIaMeta?.alias || [],
    redirect: indexts6XMVWmIaMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/standard/pages/standard/schema/index.vue").then(m => m.default || m)
  },
  {
    name: trpcnrefUGpdZVMeta?.name ?? "trpc",
    path: trpcnrefUGpdZVMeta?.path ?? "/trpc",
    meta: trpcnrefUGpdZVMeta || {},
    alias: trpcnrefUGpdZVMeta?.alias || [],
    redirect: trpcnrefUGpdZVMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/framework/pages/trpc.vue").then(m => m.default || m)
  },
  {
    name: indexYLP9SgGAjGMeta?.name ?? "user-info",
    path: indexYLP9SgGAjGMeta?.path ?? "/user/info",
    meta: indexYLP9SgGAjGMeta || {},
    alias: indexYLP9SgGAjGMeta?.alias || [],
    redirect: indexYLP9SgGAjGMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/user-space/pages/user/info/index.vue").then(m => m.default || m)
  },
  {
    name: indexfPODb6hw02Meta?.name ?? "user-security",
    path: indexfPODb6hw02Meta?.path ?? "/user/security",
    meta: indexfPODb6hw02Meta || {},
    alias: indexfPODb6hw02Meta?.alias || [],
    redirect: indexfPODb6hw02Meta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/user-space/pages/user/security/index.vue").then(m => m.default || m)
  },
  {
    name: indexh3AGrTVJlKMeta?.name ?? "yp231519",
    path: indexh3AGrTVJlKMeta?.path ?? "/yp231519",
    meta: indexh3AGrTVJlKMeta || {},
    alias: indexh3AGrTVJlKMeta?.alias || [],
    redirect: indexh3AGrTVJlKMeta?.redirect,
    component: () => import("/home/devops/workspace/webspace-zl/modules/yp231519/pages/yp231519/index.vue").then(m => m.default || m)
  }
]