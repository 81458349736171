
import { updateAppConfig } from '#app/config'
import { defuFn } from 'defu'

const inlineConfig = {
  "nuxt": {
    "buildId": "dev"
  }
}

// Vite - webpack is handled directly in #app/config
if (import.meta.hot) {
  import.meta.hot.accept((newModule) => {
    updateAppConfig(newModule.default)
  })
}

import cfg0 from "/home/devops/workspace/webspace-zl/apps/platform/app.config.ts"
import cfg1 from "/home/devops/workspace/webspace-zl/modules/organization/app.config.ts"
import cfg2 from "/home/devops/workspace/webspace-zl/modules/portal/app.config.ts"
import cfg3 from "/home/devops/workspace/webspace-zl/modules/om/app.config.ts"
import cfg4 from "/home/devops/workspace/webspace-zl/modules/yp231519/app.config.ts"
import cfg5 from "/home/devops/workspace/webspace-zl/modules/catalog/app.config.ts"
import cfg6 from "/home/devops/workspace/webspace-zl/modules/pm/app.config.ts"
import cfg7 from "/home/devops/workspace/webspace-zl/modules/user-space/app.config.ts"
import cfg8 from "/home/devops/workspace/webspace-zl/modules/project/app.config.ts"
import cfg9 from "/home/devops/workspace/webspace-zl/modules/project-org/app.config.ts"
import cfg10 from "/home/devops/workspace/webspace-zl/modules/bim/app.config.ts"
import cfg11 from "/home/devops/workspace/webspace-zl/modules/render/app.config.ts"
import cfg12 from "/home/devops/workspace/webspace-zl/modules/schedule/app.config.ts"
import cfg13 from "/home/devops/workspace/webspace-zl/modules/catalog/app.config.ts"
import cfg14 from "/home/devops/workspace/webspace-zl/modules/bom/app.config.ts"
import cfg15 from "/home/devops/workspace/webspace-zl/modules/flow/app.config.ts"
import cfg16 from "/home/devops/workspace/webspace-zl/modules/lifecycle/app.config.ts"
import cfg17 from "/home/devops/workspace/webspace-zl/modules/standard/app.config.ts"

export default /*@__PURE__*/ defuFn(cfg0, cfg1, cfg2, cfg3, cfg4, cfg5, cfg6, cfg7, cfg8, cfg9, cfg10, cfg11, cfg12, cfg13, cfg14, cfg15, cfg16, cfg17, inlineConfig)
